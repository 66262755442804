import {Injectable} from '@angular/core';
import {BeitaegeService, Beitragskategorie} from '../api';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BeitragService {
    /** Beitragsarten und Betrag */
    beitraege: Beitragskategorie[] = [];

    constructor(private beitaegeService: BeitaegeService) {
        this.initBetraegeData();
    }

    async initBetraegeData(): Promise<Beitragskategorie[]> {
        this.beitraege = await lastValueFrom(this.beitaegeService.getBeitraege());
        return this.beitraege;
    }

    /**
     * Beitragspreis anhand des Beitragskürzels
     * @param kuerzel Beitragskürzel
     * @returns Beitrag
     */
    public getBeitragbyKuerzel(kuerzel: string): number {
        const beitrag = this.beitraege.find(obj => {
            return obj.kuerzel === kuerzel;
        });
        if (beitrag !== undefined && beitrag.preis !== undefined) {
            return beitrag.preis.preis || 0;
        }
        return 0;
    }

    getBeitraege() {
        return this.beitraege;
    }
}

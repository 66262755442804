<mat-sidenav-container (backdropClick)="close('backdrop')">
    <mat-sidenav
        #sidenav
        (keydown.escape)="close('escape')"
        disableClose
        fxLayout="column"
        fxLayoutAlign="center center"
        data-cy="navBarMobile">
        <!-- Mobile Navigation -->

        <div fxFlex>
            <button mat-button routerLink="">Home</button><br />
            <button mat-button (click)="close('toggle button')" routerLink="news">Berichte</button><br />
            <button mat-button (click)="close('toggle button')" routerLink="verein/kalender">Kalender/Termine</button
            ><br />
            <button mat-button [matMenuTriggerFor]="vereinMenu">unser Verein</button><br />
            <mat-menu #vereinMenu="matMenu">
                <button mat-menu-item (click)="close('toggle button')" routerLink="verein/vorstand">Vorstand</button>
                <button mat-menu-item (click)="close('toggle button')" routerLink="verein/bootshaus">Bootshaus</button>
                <button mat-menu-item (click)="close('toggle button')" routerLink="verein/anmeldung">
                    Anmeldung/Satzung
                </button>
                <button mat-menu-item (click)="close('toggle button')" routerLink="verein/anfahrt">Anfahrt</button>
            </mat-menu>
            <button mat-button [matMenuTriggerFor]="sportMenu">Training/Sportliches</button><br />
            <mat-menu #sportMenu="matMenu">
                <button mat-menu-item (click)="close('toggle button')" routerLink="training/sommertraining">
                    Sommertraining
                </button>
                <button mat-menu-item (click)="close('toggle button')" routerLink="training/wintertraining">
                    Wintertraining
                </button>
            </mat-menu>
            <button mat-button (click)="close('toggle button')" routerLink="verein/vorstand">Kontakt</button><br />
            <button mat-button *ngIf="!isLoggedIn" (click)="close('toggle button')" routerLink="login">Login</button
            ><br *ngIf="!isLoggedIn" />
            <button
                mat-button
                *ngIf="isLoggedIn"
                (click)="close('toggle button')"
                routerLink="mitgliederbereich/dashboard">
                Mitgliederbereich</button
            ><br />
            <button mat-button *ngIf="isLoggedIn" (click)="close('toggle button'); logout()">Logout</button><br />
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <!-- Logo Header -->
        <kkm-header></kkm-header>

        <!-- Navigationstoolbar -->
        <mat-toolbar color="primary" layout-align="center center" data-cy="navBar">
            <button
                mat-button
                *ngIf="deviceScaleService.isMobileOrTablet()"
                (click)="sidenav.open()"
                class="toggle-button">
                <div
                    class="material-icon margin"
                    [ngClass]="{hamburger: !sidenavButtonState, arrow: sidenavButtonState}">
                    <span class="first"></span>
                    <span class="second"></span>
                    <span class="second"></span>
                    <span class="third"></span>
                </div>
            </button>
            <span fxFlex></span>
                <span *ngIf="!deviceScaleService.isMobileOrTablet()" fxLayout="row">
                    <button mat-button routerLink="">Home</button>
                    <button mat-button routerLink="news" data-cy="navNews">Berichte</button>
                    <button mat-button routerLink="verein/kalender" data-cy="navKalender">Kalender/Termine</button>
                    <button mat-button [matMenuTriggerFor]="aboveMenuVerein" data-cy="navVerein">unser Verein</button>
                    <mat-menu #aboveMenuVerein="matMenu">
                        <button mat-menu-item routerLink="verein/vorstand" data-cy="navVorstand">Vorstand</button>
                        <button mat-menu-item routerLink="verein/bootshaus" data-cy="navBootshaus">Bootshaus</button>
                        <button mat-menu-item routerLink="verein/kalender" data-cy="navTermine">
                            Kalender/Termine
                        </button>
                        <button mat-menu-item routerLink="verein/anmeldung" data-cy="navAnmeldung">
                            Anmeldung/Satzung
                        </button>
                        <button mat-menu-item routerLink="verein/anfahrt" data-cy="navAnfahrt">Anfahrt</button>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]="aboveMenuSport" data-cy="navSportliches">
                        Training/Sportliches
                    </button>
                    <mat-menu #aboveMenuSport="matMenu">
                        <button mat-menu-item routerLink="training/sommertraining" data-cy="navSommertraining">
                            Sommertraining
                        </button>
                        <button mat-menu-item routerLink="training/wintertraining" data-cy="navWintertraining">
                            Wintertraining
                        </button>
                    </mat-menu>
                    <button mat-button routerLink="verein/vorstand">Kontakt</button>
                    <button mat-button *ngIf="!isLoggedIn" routerLink="login" data-cy="navLogin">Login</button>
                    <button
                        mat-button
                        *ngIf="isLoggedIn"
                        routerLink="mitgliederbereich/dashboard"
                        data-cy="navMitgliederbereich">
                        Mitgliederbereich
                    </button>
                    <button mat-button *ngIf="isLoggedIn" (click)="logout()" data-cy="navLogout">Logout</button>
                </span>
            <span fxFlex></span>
        </mat-toolbar>

        <div class="content">
            <router-outlet style="background-color: darkorange;" data-cy="content"></router-outlet>
        </div>
        
        <kkm-footer></kkm-footer>
        
        <kkm-device-scale *ngIf="devUmgebung"></kkm-device-scale>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-container *ngIf="isDesktop; else mobileButtons">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-container>

<ng-template #mobileButtons>
    <button type="button" mat-icon-button matTooltip="Menü" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </mat-menu>
</ng-template>

<ng-template #contentTpl><ng-content></ng-content></ng-template>

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Verein { 
    /**
     * Vollständiger Name des Vereins
     */
    readonly name: string;
    /**
     * Straße der Postanschrift
     */
    readonly strasse: string;
    /**
     * PLZ der Postanschrift
     */
    readonly plz: string;
    /**
     * Ort der Postanschrift
     */
    readonly ort: string;
    /**
     * Name der Postanschrift
     */
    readonly anschrift_name: string;
    /**
     * Telefonnummer des Vereins
     */
    readonly telefon: string;
    /**
     * Email des Vereins
     */
    readonly email: string;
    /**
     * Zuständiges Amtsgericht
     */
    readonly amtsgericht: string;
    /**
     * Nummer beim Amtsgericht
     */
    readonly amtsgericht_nummer: string;
    /**
     * Name des Bank des Einzugskontos
     */
    readonly name_bank?: string;
    /**
     * IBAN des Einzugskontos
     */
    readonly ban?: string;
    /**
     * BIC des Einzugskontos
     */
    readonly bic?: string;
    /**
     * Gläubiger ID für den Einzug
     */
    readonly glaeubigerid?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


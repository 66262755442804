<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Impressum</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <b>Kanu-Klub Mühlheim e.V.</b><br />

            <p>
                Anschrift <br />
                {{ verein.anschrift_name }} <br />
                {{ verein.strasse }} <br />
                {{ verein.plz + ' ' + verein.ort }}
            </p>

            <p>
                Telefon: {{ verein.telefon }} <br />
                E-Mail: {{ verein.email }} <br />
                Internet: www.kanu-klub.de
            </p>

            <p>
                Vertretungsberechtigter Vorstand <br />
                1. Vorsitzender: {{ vorstand.Vorsitzender1 }} <br />
                2. Vorsitzender: {{ vorstand.Vorsitzender2 }}
            </p>

            <p>
                Registergericht: {{ verein.amtsgericht }} <br />
                Registernummer: {{ verein.amtsgericht_nummer }}
            </p>

            <p>
                Der Kanu-Klub Mühlheim e.V. (KKM) weist darauf hin, dass unsere Seiten ausschließlich privaten, nicht
                kommerziellen Zwecken dienen.
            </p>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

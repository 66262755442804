<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%"
    data-cy="SommertrainingPage">
    <mat-card fxFlex>
        <mat-card-header>
            <mat-card-title>Sommertraining</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table width="100%">
                <colgroup>
                    <col span="1" style="width: 33%" />
                    <col span="1" style="width: 33%" />
                    <col span="1" style="width: 33%" />
                </colgroup>
                <tr>
                    <td colspan="3">
                        <p>
                            In der Saison von Ende März bis Ende Oktober treffen wir uns Dienstags ab 17:30 Uhr am
                            Bootshaus. Von dort wird gemeinsam oder in kleineren Gruppen gestartet. Gepaddelt wird auf
                            dem Main, je nach Lust und Laune in Wanderbooten, Seekajaks und Kanadiern. Auch Wildwasser-
                            und Poloboote stehen zur Verfügung. Sehr beliebt ist auch das Paddeln im Stehen auf den
                            SUP-Boards.
                        </p>
                        <p>
                            So gut wie immer findet sich eine Gruppe zur gemütlichen Feierabendrunde zusammen.
                            AnfängerInnen zum Schnupperpaddeln sind hier herzlich willkommen.
                        </p>
                        <p>
                            Zum sportlicheren Paddeln finden sich jene ein, die mit höherem Tempo auf die längere
                            Strecke (ca. 15km) gehen. Hier kommen Seekajaks, Surfskis und Abfahrtsboote zum Einsatz.
                            Klar, dass im Anschluss an das Training das gemütliche Beisammensein auf der Terrasse des
                            Bootshauses nicht zu kurz kommt.
                        </p>
                        <p>
                            Eine Besonderheit ist unsere Kanupolo-Anlage auf dem Main. Wir haben zur Zeit keine aktive
                            Mannschaft aber in den letzten Jahren wird wieder vermehrt Kanupolo gespielt. Die Gruppe
                            setzt sich aus PaddlerInnen verschiedener Kanuvereine zusammen. Gespielt wird meist
                            Mittwochs.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td><img src="\assets\img\sportliches/sommer/sommer_1.jpg" width="100%" /></td>
                    <td><img src="\assets\img\sportliches/sommer/sommer_2.jpg" width="100%" /></td>
                    <td><img src="\assets\img\sportliches/sommer/sommer_3.jpg" width="100%" /></td>
                </tr>
                <tr>
                    <td><img src="\assets\img\sportliches/sommer/sommer_4.jpg" width="100%" /></td>
                    <td><img src="\assets\img\sportliches/sommer/sommer_5.jpg" width="100%" /></td>
                    <td><img src="\assets\img\sportliches/sommer/sommer_6.jpg" width="100%" /></td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Anrede } from '../model/models';
import { Geschlecht } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UtilServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Liste aller möglichen Anreden. Erstmal nur Grundlegende, kann jederzeit bei Bedarf einfach erweitert werden.
     * 
     */
    getAnreden(extraHttpRequestParams?: any): Observable<Array<Anrede>>;

    /**
     * Liste aller möglichen Geschlechter. Erstmal nur Grundlegende, kann aber auch einfach erweitert werden.
     * 
     */
    getGeschlechter(extraHttpRequestParams?: any): Observable<Array<Geschlecht>>;

}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Bootsplatz } from './bootsplatz';
import { Bootsart } from './bootsart';
import { Bootsklasse } from './bootsklasse';
import { User } from './user';
import { Datei } from './datei';
import { Bootskosten } from './bootskosten';


export interface Boot { 
    id?: number;
    /**
     * Id des Eigentümers
     */
    user_id?: number | null;
    /**
     * Gibt an ob es sich um ein Boot (1), Zubehör (2) oder Inventar (3) ist
     */
    kategorie?: number;
    /**
     * Name/Nummer des Bootes
     */
    name?: string;
    /**
     * KKM Klasse
     */
    bootsklasse_id?: number;
    /**
     * Größe des Bootes
     */
    groesse?: string;
    /**
     * Art des Bootes/Zubehör
     */
    bootsart_id?: number;
    /**
     * Hersteller
     */
    hersteller?: string;
    /**
     * Model des Herstellers
     */
    model?: string;
    /**
     * Farbe des Bootes
     */
    farbe?: string;
    /**
     * Material des Bootes
     */
    material?: string;
    /**
     * Kaufdatum
     */
    kaufdatum?: Date;
    /**
     * Beschreibung des Boots, zusätzliche Angaben
     */
    beschreibung?: string;
    user?: User;
    bild?: Datei;
    /**
     * Bilder
     */
    bilder?: Array<Datei>;
    bootsplatz?: Bootsplatz;
    bootsklasse?: Bootsklasse;
    bootsart?: Bootsart;
    kosten?: Bootskosten;
    /**
     * MorphTo Class
     */
    morphClass?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


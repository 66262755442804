import {Component, Input} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {FileService} from 'src/app/api';
import {AuthenticationService} from 'src/app/services/authentication.service';

@Component({
    selector: 'kkm-image-rotate',
    templateUrl: './image-rotate.component.html',
    styleUrls: ['./image-rotate.component.scss'],
})
export class ImageRotateComponent {
    constructor(private fileService: FileService, public authService: AuthenticationService) {}

    @Input() imageId: number | undefined;

    rotateLeft(): void {
        if (this.imageId) lastValueFrom(this.fileService.rotateImage(this.imageId, '90'));
    }

    rotateRight(): void {
        if (this.imageId) lastValueFrom(this.fileService.rotateImage(this.imageId, '-90'));
    }
}

import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {lastValueFrom} from 'rxjs';
import {News, NewsService} from 'src/app/api';

@Component({
    selector: 'kkm-startseite',
    templateUrl: './startseite.component.html',
    styleUrls: ['./startseite.component.scss'],
})
export class StartseiteComponent implements OnInit {
    slides = [
        {image: '/assets/img/startseite/KKM-Slideshow-1.jpg'},
        {image: '/assets/img/startseite/KKM-Slideshow-2.jpg'},
        {image: '/assets/img/startseite/KKM-Slideshow-3.jpg'},
        {image: '/assets/img/startseite/KKM-Slideshow-4.jpg'},
    ];

    sportbereiche = [
        {
            image: '/assets/img/startseite/wander_1.jpg',
            titel: 'Termine',
            text: 'Hier findet Ihr die Termine die dieses Jahr noch anstehen.',
            mehr: '/verein/kalender',
        },
        {
            image: '/assets/img/startseite/sommer_1.jpg',
            titel: 'Sommertraining',
            text: 'In der Saison von Ende März bis Ende Oktober treffen wir uns Dienstags ab 17:30 Uhr am Bootshaus. Von dort wird gemeinsam oder in kleineren Gruppen gestartet. Gepaddelt wird auf dem Main, je nach Lust und Laune in Wanderbooten, Seekajaks und Kanadiern. Auch Wildwasser- und Poloboote stehen zur Verfügung. Sehr beliebt ist auch das Paddeln im Stehen auf den SUP-Boards.',
            mehr: '/training/sommertraining',
        },
        {
            image: '/assets/img/startseite/polo_1.jpg',
            titel: 'Wintertraining',
            text: 'In der Wintersaison zwischen November und Ende März paddeln nur einige Unentwegte. Die sportlichen Aktivitäten verlagern sich nach drinnen. Mittwochs ab 19:30 Uhr besteht die Möglichkeit zum Kraftausdauertraining in der Turnhalle des FEG Mühlheim...',
            mehr: '/training/wintertraining',
        },
    ];

    pageSize = 4;
    pageEvent: PageEvent | undefined;

    news: {image: string; titel: string; text: string; mehr?: string; datum?: Date | undefined}[] = [];
    newsListe: News[] = [];
    isLoading = true;

    constructor(private newService: NewsService) {}

    ngOnInit(): void {
        this.initNewslistData();
    }

    async initNewslistData(): Promise<News[]> {
        this.newsListe = await lastValueFrom(this.newService.getAllNews());
        for (let i = 0; i < 4 && i < this.newsListe.length; i++) {
            const element = this.newsListe[i];

            this.news.push({
                image: '',
                titel: element.betreff || '',
                text: element.beschreibung || '',
                mehr: '/news',
                datum: element.datum,
            });
        }
        this.isLoading = false;
        return this.newsListe;
    }
}

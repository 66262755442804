/**
 * enum with all available devices
 */
export enum DeviceEnum {
    Mobile,
    Tablet,
    Laptop,
    Desktop,
    TV,
    None,
}

<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-sm="75%"
    fxFlex.sm="100%"
    data-cy="SatzungPage">
    <mat-card fxFlex>
        <mat-card-header>
            <mat-card-title>Anmeldung/Satzung</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayout="column">
            <div>
                <p>
                    Du bist ein Leidenschaftlicher Paddler und möchtest auch zum Verein dazu gehören?<br />
                    <br />
                    Das geht ganz einfach: Anmeldeformular ausdrucken, ausfüllen und am besten persönlich bei einem
                    Vorstandsmitglied abgeben.<br />
                    <br />
                    <a href="\assets\downloads\Aufnahmeantrag.pdf">Anmeldeformular (.pdf)</a><br />
                    <br />
                    <a href="\assets\downloads\Satzung_1995.pdf">Vereinssatzung (.pdf)</a><br />
                    <br />
                    <a href="\assets\downloads\Unbedenklichkeitserklaerung_Minderjährige.pdf"
                        >Unbedenklichkeitserklaerung für Minderjährige (.pdf)</a
                    ><br />
                </p>
                <p>Unsere Mitgliedsbeiträge pro Jahr gemäß Beschluss der JHV vom 14.03.2017</p>
                <p></p>
            </div>

            <div>
                <div fxFlex></div>
                <div fxFlex.gt-md="75%" fxFlex.md="100%">
                    <kkm-vereinsbeitraege></kkm-vereinsbeitraege>
                </div>
                <div fxFlex></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

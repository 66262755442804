import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ToNamePipe} from './to-name.pipe';
import {BootEigentuemerPipe} from './boot-eigentuemer.pipe';
import {BootNamePipe} from './boot-name.pipe';
import {TruncatePipe} from './truncate.pipe';
import {Nl2brPipe} from './nl2br.pipe';
import {IbanPipe} from './iban.pipe';
import {AgePipe} from './age.pipe';
import {TokenPipe} from './token.pipe';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        ToNamePipe,
        BootEigentuemerPipe,
        BootNamePipe,
        TruncatePipe,
        Nl2brPipe,
        AgePipe,
        IbanPipe,
        TokenPipe,
        FileSizePipe,
    ],
    exports: [ToNamePipe, BootEigentuemerPipe, BootNamePipe, TruncatePipe, Nl2brPipe, AgePipe, IbanPipe, TokenPipe, FileSizePipe],
})
export class PipesModule {}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface Mailtracking { 
    /**
     * Empfänger
     */
    user_id?: number | null;
    /**
     * Adresse an die Verschickt wurde
     */
    readonly mailadresse?: string;
    /**
     * Betreff der Mail
     */
    readonly betreff?: string;
    /**
     * Datum des Sendens
     */
    gesendet?: Date;
    /**
     * Datum des Lesens
     */
    gelesen?: Date;
    /**
     * evtl. Inhalt der Mail
     */
    readonly inhalt?: string;
    /**
     * Fehlermeldung
     */
    readonly fehlermeldung?: string;
    user?: User;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject3 { 
    /**
     * Altes Passwort
     */
    altes_passwort?: string;
    /**
     * neues Passwort
     */
    neues_passwort?: string;
}


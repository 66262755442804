/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Rechnung } from '../model/models';
import { User } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BankServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Edit Rechnung
     * 
     * @param id ID der Rechnung
     * @param rechnung Ändern einer Rechnung
     */
    editRechnung(id: number, rechnung: Rechnung, extraHttpRequestParams?: any): Observable<Rechnung>;

    /**
     * Gebühren anwenden
     * 
     * @param id ID der Rechnung
     */
    gebuehrenAnwenden(id: number, extraHttpRequestParams?: any): Observable<Rechnung>;

    /**
     * 
     * 
     */
    getAbbrechnungsdaten(extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * 
     * 
     */
    getNewSepa(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Daten einer Rechnung
     * 
     * @param id ID der Rechnung
     */
    getRechnung(id: number, extraHttpRequestParams?: any): Observable<Rechnung>;

    /**
     * Liste aller Rechnungen
     * 
     */
    getRechnungsliste(extraHttpRequestParams?: any): Observable<Array<Rechnung>>;

    /**
     * Neue Rechnung anlegen
     * 
     * @param rechnung Daten einer neuen Rechnung
     */
    newRechnung(rechnung: Rechnung, extraHttpRequestParams?: any): Observable<Rechnung>;

}

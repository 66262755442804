import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BootshausComponent} from './pages/bootshaus/bootshaus.component';
import {LayoutComponent} from './pages/layout/layout.component';
import {VorstandComponent} from './pages/vorstand/vorstand.component';
import {ImpressumComponent} from './pages/impressum/impressum.component';
import {LoginComponent} from './pages/login/login.component';
import {StartseiteComponent} from './pages/startseite/startseite.component';
import {AnmeldungSatzungComponent} from './pages/anmeldung-satzung/anmeldung-satzung.component';
import {KalenderPageComponent} from './pages/kalender-page/kalender-page.component';
import {AnfahrtComponent} from './pages/anfahrt/anfahrt.component';
import {SommertrainingComponent} from './pages/training/sommertraining/sommertraining.component';
import {WintertrainingComponent} from './pages/training/wintertraining/wintertraining.component';
import {NewsPageComponent} from './pages/news-page/news-page.component';
import {DisclaimerComponent} from './pages/disclaimer/disclaimer.component';
import {DatenschutzComponent} from './pages/datenschutz/datenschutz.component';
import {PasswortVergessenPageComponent} from './pages/passwort-vergessen-page/passwort-vergessen-page.component';

const routes: Routes = [
    {path: '', redirectTo: '/startseite', pathMatch: 'full'},
    {
        path: '',
        component: LayoutComponent,
        children: [
            {path: 'startseite', component: StartseiteComponent},
            {path: 'news', component: NewsPageComponent},
            {
                path: 'verein',
                children: [
                    {path: '', redirectTo: 'vorstand', pathMatch: 'full'},
                    {path: 'vorstand', component: VorstandComponent},
                    {path: 'bootshaus', component: BootshausComponent},
                    {path: 'kalender', component: KalenderPageComponent},
                    {path: 'anmeldung', component: AnmeldungSatzungComponent},
                    {path: 'anfahrt', component: AnfahrtComponent},
                    // { path: 'downloads', component: DownloadsComponent },
                ],
            },
            {
                path: 'training',
                children: [
                    {path: '', redirectTo: 'sommertraining', pathMatch: 'full'},
                    {path: 'sommertraining', component: SommertrainingComponent},
                    {path: 'wintertraining', component: WintertrainingComponent},
                    // { path: 'afterwork', component: AfterWorkComponent },
                    // { path: 'schnupperpaddeln', component: SchnupperpaddelnComponent },
                    // { path: 'kleinflussfahrt', component: KleinflussfahrtComponent },
                    // { path: 'wildwasser', component: WildwasserComponent },
                    // { path: 'kanupolo', component: KanuPoloComponent },
                ],
            },
            {path: 'impressum', component: ImpressumComponent},
            {path: 'disclaimer', component: DisclaimerComponent},
            {path: 'datenschutz', component: DatenschutzComponent},

            {path: 'login', component: LoginComponent},
            {path: 'passwortVergessen', component: PasswortVergessenPageComponent},

            {
                path: 'mitgliederbereich',
                loadChildren: () =>
                    import('./mitgliederbereich/mitgliederbereich.module').then(m => m.MitgliederbereichModule),
            },
        ],
    },

    // {
    //   path: 'error',
    //   component: ErrorLandingPageComponent
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Mitgliedskosten } from './mitgliedskosten';
import { ReGruppe } from './reGruppe';
import { Schluessel } from './schluessel';
import { Datei } from './datei';
import { Geschlecht } from './geschlecht';
import { Bankdaten } from './bankdaten';
import { Boot } from './boot';
import { Anrede } from './anrede';


export interface User { 
    readonly id?: number;
    /**
     * Mitglieder, die zu einem anderen gehören
     */
    hauptmitglied_id?: number | null;
    /**
     * Username für den Login zum Mitgliederbereich
     */
    loginname: string;
    /**
     * Passwort für Mitgliederbereich
     */
    password?: string;
    /**
     * Verweis auf Geschlecht des Mitglieds
     */
    geschlecht_id?: number;
    /**
     * Verweis auf Anrede für die Person
     */
    anrede_id?: number;
    /**
     * Vorname
     */
    vorname: string;
    /**
     * Nachname
     */
    nachname: string;
    /**
     * Geburtsdatum
     */
    geburtsdatum?: Date;
    /**
     * Postleitzahl
     */
    plz?: string;
    /**
     * Wohnort
     */
    wohnort?: string;
    /**
     * Straße inklusive Hausnummer
     */
    strasse?: string;
    /**
     * Vorwahl
     */
    vorwahl?: string;
    /**
     * Telefonnummer
     */
    telefon?: string;
    /**
     * Handynummer
     */
    handy?: string;
    /**
     * Email
     */
    email?: string;
    /**
     * Benachrichtigungen per Brief Nein(0)
     */
    brief?: boolean;
    /**
     * Sepa Nr
     */
    sepa?: number;
    /**
     * Einzug des Jahresbeitrags oder Überweisung
     */
    einzug?: boolean;
    /**
     * Status des Mitglieds
     */
    status?: string;
    /**
     * Datum des Eintritts
     */
    eintrittsdatum?: Date;
    /**
     * Datum des Austritts
     */
    austrittsdatum?: Date;
    /**
     * Kann sich das Mitglied einloggen
     */
    login_status?: boolean;
    /**
     * E-Mail Benachrichtigung auf dem Schwarzen Brett
     */
    sw_brett_mail?: boolean;
    /**
     * Art der Mitgliedschaft (Erwachsener, Kind, Familie, Förder)
     */
    beitragskategorie_id?: number;
    /**
     * Geocoordinaten des Wohnortes
     */
    lat?: number;
    /**
     * Geocoordinaten des Wohnortes
     */
    lng?: number;
    /**
     * Aufnahmegebühr eingezogen?
     */
    aufnahmegebuehr?: boolean;
    /**
     * Erst Lagerkosten eingezogen?
     */
    bootslagergebuehr?: boolean;
    /**
     * Hash der Bankverbindung um Prüfen einer Änderung
     */
    kontohash?: string;
    /**
     * Letzte Stellen der Kontonummer zur Kontoauswahl
     */
    kontoendung?: string;
    /**
     * Anmerkungen zum Mitglied
     */
    anmerkung?: string;
    /**
     * Familienmitglieder
     */
    familienmitglieder?: Array<User>;
    /**
     * Boote
     */
    boote?: Array<Boot>;
    anrede?: Anrede;
    geschlecht?: Geschlecht;
    schluessel?: Schluessel;
    bild?: Datei;
    /**
     * Bilder
     */
    bilder?: Array<Datei>;
    /**
     * Rechte Gruppen
     */
    gruppen?: Array<ReGruppe>;
    kosten?: Mitgliedskosten;
    bankdaten?: Bankdaten;
    /**
     * MorphTo Class
     */
    morphClass?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { Buchungskonto } from './buchungskonto';
import { Beleg } from './beleg';
import { Bankkonto } from './bankkonto';


export interface Buchung { 
    id: number;
    bezeichnung: string;
    beschreibung?: string;
    zahlerEmpfaenger?: string;
    /**
     * Id des Erfassers
     */
    user_id?: number | null;
    /**
     * Id des Buchungskontos
     */
    buchungskonto_id?: number | null;
    /**
     * Id des Bankkontos
     */
    bankkonto_id?: number | null;
    /**
     * Buchungsdatum
     */
    buchungsdatum: Date;
    /**
     * Id des Bankkontos
     */
    betrag: number;
    /**
     * ist die Buchung abgeschlossen?
     */
    abgeschlossen?: boolean | null;
    user?: User;
    buchungskonto?: Buchungskonto;
    bankkonto?: Bankkonto;
    /**
     * Verknüfte Belege
     */
    belege?: Array<Beleg>;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


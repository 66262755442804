import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {Router} from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import {DeviceScaleService} from 'src/app/services/device-scale.service';
import {DeviceEnum} from 'src/app/model/deviceEnum';
import {Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {User} from 'src/app/api';

@Component({
    selector: 'kkm-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav') sidenav!: MatSidenav;

    reason = '';
    isLoggedIn = false;
    mobile = false;
    public sidenavButtonState = false;
    private readonly loginSubscription: Subscription;

    devUmgebung = !environment.production;
    me: User = {loginname: '', vorname: '', nachname: ''};

    private device!: DeviceEnum;
    // private readonly deviceSubscription: Subscription;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        public deviceScaleService: DeviceScaleService
    ) {
        this.loginSubscription = authService
            .asObservable()
            .subscribe((loginStatus: boolean) => (this.isLoggedIn = loginStatus));
        this.me = this.authService.getMe();
    }

    ngOnInit(): void {
        this.isLoggedIn = this.authService.isLoggedIn();
    }

    public ngOnDestroy(): void {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }

    logout(): void {
        this.authService.logout();
        this.router.navigateByUrl('/');
    }

    close(reason: string): void {
        this.reason = reason;
        this.sidenav.close();
    }
}

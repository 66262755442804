<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%"
    data-cy="NewsPage">
    <kkm-news-card-large *ngIf="newEntry" (newsChange)="reloadEntrys()" data-cy="newNewsCard"> </kkm-news-card-large>

    <kkm-news-card-large
        *ngFor="let card of newsListe"
        [news]="card"
        (newsChange)="reloadEntrys()"
        [showButtons]="isLoggedIn"
        data-cy="NewsCard"></kkm-news-card-large>

    <mat-spinner *ngIf="isLoading" class="spinner"></mat-spinner>
</div>
<span fxFlex></span>

<button
    mat-fab
    matTooltip="Neuen Beitrag erstellen"
    [ngClass]="'md-fab md-fab-bottom-right' + (deviceScaleService.isMobileOrTablet() ? '-mobile' : '')"
    color="primary"
    aria-label="Add a News"
    (click)="newEntry = !newEntry"
    [disabled]="newEntry"
    *ngIf="authService.hasRight('new news')"
    data-cy="fabButton">
    <mat-icon>add</mat-icon>
</button>

import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {DeviceEnum} from '../model/deviceEnum';
import {DeviceScaleService} from '../services/device-scale.service';

@Component({
    selector: 'kkm-device-scale',
    template: `
        <!-- <mat-card> -->
        <p>Aktuelles Gerät: {{ currentDevice }}</p>
        <div *ngIf="deviceScaleService.isMobile()" style="width: 100%; height: 20px; background-color: tomato;"></div>
        <div *ngIf="deviceScaleService.isTablet()" style="width: 100%; height: 20px; background-color: orange;"></div>
        <div *ngIf="deviceScaleService.isLaptop()" style="width: 100%; height: 20px; background-color: yellow;"></div>
        <div
            *ngIf="deviceScaleService.isDesktop()"
            style="width: 100%; height: 20px; background-color: greenyellow;"></div>
        <div *ngIf="deviceScaleService.isTV()" style="width: 100%; height: 20px; background-color: limegreen;"></div>

        <!-- <markdown src="assets/services/device-scale.md"></markdown> -->
        <!-- </mat-card> -->
    `,
    styles: [``],
})
export class DeviceScaleComponent implements OnDestroy {
    private device!: DeviceEnum;
    private readonly deviceSubscription: Subscription;

    public constructor(public deviceScaleService: DeviceScaleService) {
        this.deviceSubscription = deviceScaleService
            .asObservable()
            .subscribe((device: DeviceEnum) => (this.device = device));
    }

    public get currentDevice(): string {
        switch (this.device) {
            case DeviceEnum.None:
                return 'unbekannt';
            case DeviceEnum.Mobile:
                return 'Mobile xs';
            case DeviceEnum.Tablet:
                return 'Tablet sm';
            case DeviceEnum.Laptop:
                return 'Laptop md';
            case DeviceEnum.Desktop:
                return 'Desktop lg';
            case DeviceEnum.TV:
                return 'TV xl';
        }

        return 'unbekannt';
    }

    public ngOnDestroy(): void {
        if (this.deviceSubscription) {
            this.deviceSubscription.unsubscribe();
        }
    }
}

<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%"
    data-cy="KalenderPage">
    <mat-card fxFlex="100%">
        <mat-card-header>
            <mat-card-title>Termine</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div
                fxLayout.gt-md="row"
                fxLayout.lt-sm="column"
                fxLayoutAlign="space-evenly center"
                fxLayoutGap="10px"
                style="padding: 10px"
                data-cy="KalenderHeader">
                <span fxFlex></span>
                <!-- Vor - Heute - Zurück Buttons -->
                <div fxFlex="30%" *ngIf="view !== CalendarView.Day" style="text-align: center">
                    <button
                        mat-raised-button
                        mwlCalendarPreviousView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="viewDateChange()">
                        Zurück
                    </button>
                    <button mat-raised-button mwlCalendarToday [(viewDate)]="viewDate">Heute</button>
                    <button
                        mat-raised-button
                        mwlCalendarNextView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="viewDateChange()">
                        Vor
                    </button>
                </div>
                <div fxFlex="30%" *ngIf="view === CalendarView.Day" style="text-align: center">
                    <button
                        mat-raised-button
                        *ngIf="viewDate > minKalenderDate"
                        (click)="viewDate.setFullYear(viewDate.getFullYear() - 1); viewDateChange()">
                        Zurück
                    </button>
                    <button mat-raised-button (click)="viewDate = heute; viewDateChange()">Heute</button>
                    <button
                        mat-raised-button
                        *ngIf="viewDate < maxKalenderDate"
                        (click)="viewDate.setFullYear(viewDate.getFullYear() + 1); viewDateChange()">
                        Vor
                    </button>
                </div>

                <!-- Anzeige Text -->
                <div fxFlex="30%" *ngIf="view !== CalendarView.Day" style="text-align: center">
                    <h3>{{ viewDate | calendarDate : view + 'ViewTitle' : 'de' }}</h3>
                </div>
                <div fxFlex="30%" *ngIf="view === CalendarView.Day" style="text-align: center">
                    <h3>{{ viewDate.getFullYear() }}</h3>
                </div>

                <!-- Liste - Monat Buttons -->
                <div fxFlex="30%" style="text-align: center">
                    <mat-button-toggle-group
                        name="Kalenderansicht"
                        aria-label="Kalenderansicht"
                        [(ngModel)]="Kalenderansicht">
                        <mat-button-toggle value="Liste" (click)="setView(CalendarView.Day)">Liste</mat-button-toggle>
                        <mat-button-toggle value="Month" (click)="setView(CalendarView.Month)">Monat</mat-button-toggle>
                        <!-- <mat-button-toggle value="Week" (click)="setView(CalendarView.Week)">Woche</mat-button-toggle> -->
                    </mat-button-toggle-group>
                </div>

                <span fxFlex></span>
            </div>

            <!-- New Entry -->
            <kkm-termin-form
                *ngIf="showTerminForm"
                [termin]="editTermin"
                (terminChange)="changeKalenderEntry($event)"></kkm-termin-form>

            <!-- Kalender Listenansicht -->
            <div [ngSwitch]="view">
                <div *ngSwitchCase="CalendarView.Day">
                    <section class="table-container mat-elevation-z8">
                        <table
                            mat-table
                            [dataSource]="termine"
                            multiTemplateDataRows
                            data-cy="terminListe">
                            <ng-container matColumnDef="datum">
                                <th mat-header-cell *matHeaderCellDef>Datum</th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    (click)="
                                        element.beschreibung === ''
                                            ? null
                                            : (expandedElement = expandedElement === element ? null : element)
                                    ">
                                    {{ element.datum | date }}
                                    {{
                                        element.datum_bis && element.datum !== element.datum_bis
                                            ? ' - ' + (element.datum_bis | date)
                                            : ''
                                    }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="betreff">
                                <th mat-header-cell *matHeaderCellDef>Betreff</th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    (click)="
                                        element.beschreibung === ''
                                            ? null
                                            : (expandedElement = expandedElement === element ? null : element)
                                    ">
                                    {{ element.betreff }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="mobile">
                                <th mat-header-cell *matHeaderCellDef>Termine</th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    (click)="
                                        element.beschreibung === ''
                                            ? null
                                            : (expandedElement = expandedElement === element ? null : element)
                                    ">
                                    <div class="date">
                                        <b>
                                            {{ element.datum | date }}
                                            {{
                                                element.datum_bis && element.datum !== element.datum_bis
                                                    ? ' - ' + (element.datum_bis | date)
                                                    : ''
                                            }}
                                        </b>
                                    </div>
                                    <div class="text">{{ element.betreff }}</div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="buttons">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <button
                                        mat-icon-button
                                        *ngIf="authService.hasRight('edit event')"
                                        (click)="editKalenderEntry(element)"
                                        data-cy="kalenderEditBtn">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button
                                        mat-icon-button
                                        *ngIf="authService.hasRight('delete event')"
                                        (click)="deleteKalenderEntry(element)"
                                        data-cy="kalenderDeleteBtn">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="buttonsMobile">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button matTooltip="mehr Details" [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button
                                            mat-menu-item
                                            *ngIf="authService.hasRight('edit event')"
                                            (click)="editKalenderEntry(element)"
                                            data-cy="kalenderEditBtn">
                                            Ändern
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button
                                            mat-menu-item
                                            *ngIf="authService.hasRight('delete event')"
                                            (click)="deleteKalenderEntry(element)"
                                            data-cy="kalenderDeleteBtn">
                                            Löschen
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="expand">
                                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    (click)="
                                        element.beschreibung === ''
                                            ? null
                                            : (expandedElement = expandedElement === element ? null : element)
                                    ">
                                    <button
                                        mat-icon-button
                                        aria-label="expand row"
                                        *ngIf="element.beschreibung !== '' && element.beschreibung !== null"
                                        (click)="
                                            expandedElement = expandedElement === element ? null : element;
                                            $event.stopPropagation()
                                        ">
                                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [attr.colspan]="columnsToDisplayWithExpand.length">
                                    <div
                                        class="termin-detail"
                                        [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                                        <div
                                            class="termin-description"
                                            [innerHTML]="element.beschreibung | linky | nl2br">
                                            <!-- {{element.trm_beschreibung}} -->
                                            <!-- <span class="termin-description-attribution"> -- Wikipedia </span> -->
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>

                            <tr
                                mat-row
                                *matRowDef="let element; columns: columnsToDisplayWithExpand; let i = index"
                                class="termin-row"
                                [class.termin-row]="expandedElement === element"
                                data-cy="dataRow"
                                [attr.id]="'termin-' + element.id"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: ['expandedDetail']"
                                class="example-detail-row"
                                data-cy="detailRow"></tr>

                                <tr class="mat-mdc-row mdc-data-table__row" *matNoDataRow>
                                    <td class="mat-mdc-cell mdc-data-table__cell" style="text-align: center;"  [colSpan]="columnsToDisplayWithExpand.length">Keine Termine zum Anzeigen vorhanden</td>
                                </tr>
                        </table>
                    </section>
                    <div *ngIf="isLoading">
                        <mat-spinner class="spinner"></mat-spinner>
                    </div>
                </div>
                <mwl-calendar-month-view
                    *ngSwitchCase="CalendarView.Month"
                    [viewDate]="viewDate"
                    (dayClicked)="dayClicked($event.day)"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                    [activeDayIsOpen]="activeDayIsOpen"
                    [events]="events"
                    [weekStartsOn]="1">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events">
                </mwl-calendar-week-view>
            </div>

            <!-- 
  <mwl-calendar-month-view
  [viewDate]="viewDate"
  [events]="events"
  >
 </mwl-calendar-month-view> -->
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

<button
    mat-fab
    matTooltip="neuen Termin"
    [ngClass]="'md-fab md-fab-bottom-right' + (deviceScaleService.isMobileOrTablet() ? '-mobile' : '')"
    color="primary"
    aria-label="Add a Entry"
    *ngIf="authService.hasRight('new event')"
    (click)="newKalenderEntry()"
    data-cy="fabButton">
    <mat-icon>add</mat-icon>
</button>

import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {IAlbum} from 'ngx-lightbox/lightbox-event.service';
import {Lightbox} from 'ngx-lightbox/lightbox.service';
import {BASE_PATH, FileService, News, NewsService} from 'src/app/api';
import {FileUploadAnswer} from 'src/app/model/fileUploadAnswer';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {NotificationService} from 'src/app/services/notification/notification.service';

@Component({
    selector: 'kkm-news-card-large',
    templateUrl: './news-card-large.component.html',
    styleUrls: ['./news-card-large.component.scss'],
})
export class NewsCardLargeComponent implements OnInit {
    @Input() news: News | undefined;
    @Output() newsChange = new EventEmitter<boolean>();

    @Input() showButtons = false;

    showEditForm = false;
    showImageUpload = false;
    fileUrl = '';
    private _album: IAlbum[] = [];

    @ViewChild('fileInput') fileInput: ElementRef = {} as ElementRef;
    @ViewChild('picturePreview') picturePreview: ElementRef = {} as ElementRef;

    newsForm = new UntypedFormGroup({});

    constructor(
        private newService: NewsService,
        private fileService: FileService,
        public authService: AuthenticationService,
        private notification: NotificationService,
        private _lightbox: Lightbox,
        @Optional() @Inject(BASE_PATH) public basePath: string
    ) {}

    ngOnInit(): void {
        if (this.news) {
            this.fileUrl = `${this.fileService.configuration.basePath}/api/file/uploadFile/news/${encodeURIComponent(
                String(this.news.id)
            )}`;
            this.basePath = this.fileService.configuration.basePath || '';

            this.calculateLightbox();
        } else {
            this.initForm();
        }
    }

    calculateLightbox() {
        if (this.news && this.news.bilder) {
            this._album = [];
            for (let i = 0; i < this.news.bilder.length; i++) {
                const bild = this.news.bilder[i];
                const src = `${this.basePath}/file/serve/${bild.id || ''}`;
                const thumb = `${this.basePath}/file/serve/${bild.id || ''}/thumb`;
                const album = {src: src, thumb: thumb};
                this._album.push(album);
            }
        }
    }

    initForm() {
        this.newsForm = new FormGroup({
            betreff: new FormControl('', Validators.required),
            beschreibung: new FormControl('', Validators.required),
            freigegeben: new FormControl(false, Validators.required),
        });
        if (this.news) {
            this.newsForm.patchValue(this.news);
        }
    }

    openLightbox(index: number): void {
        this._lightbox.open(this._album, index);
    }

    abbrechen(): void {
        this.newsChange.emit(false);
    }

    speichern(): void {
        if (!this.newsForm.valid) {
            this.notification.error('Es sind nicht alle Felder ausgefüllt');
        }
        if (this.newsForm.valid) {
            const val = this.newsForm.value;
            let newNews: News = {
                betreff: '',
                datum: new Date(),
            };
            newNews = Object.assign(newNews, val);

            // Anlegen
            if (!this.news) {
                this.newService
                    .newNewsEntry(newNews)
                    .toPromise()
                    .then(() => {
                        this.newsChange.emit(true);
                    });
            }
            if (this.news && this.news.id) {
                this.newService
                    .editNewsEntry(this.news.id, newNews)
                    .toPromise()
                    .then(() => {
                        this.newsChange.emit(true);
                    });
            }
        }
    }

    editEntry(): void {
        this.initForm();
        this.showEditForm = true;
    }

    deleteEntry(): void {
        if (this.news && this.news.id) {
            this.newService
                .deleteNewsEntry(this.news.id)
                .toPromise()
                .then(() => {
                    this.newsChange.emit(true);
                });
        }
    }

    uploadChange(answer: FileUploadAnswer) {
        if (this.news && this.news?.bilder)
            if (
                answer &&
                answer.event &&
                answer.event.body &&
                answer.event.body.message == 'File successfully uploaded'
            ) {
                this.news.bilder.push(answer.event.body.file);
                this.calculateLightbox();
            }
    }

    deletePic(bildId: number | undefined): void {
        if (bildId !== undefined) {
            this.fileService
                .deleteFile(bildId)
                .toPromise()
                .then(() => {
                    this.newsChange.emit(true);
                });
        }
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'kkm-anmeldung-satzung',
    templateUrl: './anmeldung-satzung.component.html',
    styleUrls: ['./anmeldung-satzung.component.scss'],
})
export class AnmeldungSatzungComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Aufgabe } from '../model/models';
import { AufgabeStatus } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface AufgabenServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Change Aufgabenstatus
     * 
     * @param id ID der Aufgabe
     * @param status 
     */
    changeAufgabenstatus(id: number, status: AufgabeStatus, extraHttpRequestParams?: any): Observable<Aufgabe>;

    /**
     * Delete termin Entry
     * 
     * @param id ID des Eintrags
     */
    deleteAufgabe(id: number, extraHttpRequestParams?: any): Observable<Aufgabe>;

    /**
     * Edit Aufgabe
     * 
     * @param id ID der Aufgabe
     * @param aufgabe Ändern einer Aufgabe
     */
    editAufgabe(id: number, aufgabe: Aufgabe, extraHttpRequestParams?: any): Observable<Aufgabe>;

    /**
     * Liste aller Boote
     * 
     */
    getAufgabenliste(extraHttpRequestParams?: any): Observable<Array<Aufgabe>>;

    /**
     * Neue Aufgabe anlegen
     * 
     * @param aufgabe Daten einer neuen Aufgabe
     */
    newAufgabe(aufgabe: Aufgabe, extraHttpRequestParams?: any): Observable<Aufgabe>;

}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Aenderungsprotokoll } from '../model/models';
import { Anmeldeprotokoll } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProtokolleServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Anmeldeprotokoll
     * 
     */
    getAenderungsprotokoll(extraHttpRequestParams?: any): Observable<Array<Aenderungsprotokoll>>;

    /**
     * Anmeldeprotokoll
     * 
     */
    getAnmeldeprotokoll(extraHttpRequestParams?: any): Observable<Array<Anmeldeprotokoll>>;

}

import {Pipe, PipeTransform} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';

@Pipe({
    name: 'token',
})
export class TokenPipe implements PipeTransform {
    constructor(private authService: AuthenticationService) {}

    transform(value: string): string {
        const newTokenValue = this.authService.getToken();

        const regex = /\$TOKEN\$/g;

        const modifiedValue = value.replace(regex, newTokenValue);

        return modifiedValue;
    }
}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Datei { 
    /**
     * Id der Datei
     */
    id?: number;
    /**
     * User der die Datei hochgeladen hat
     */
    user_id?: number;
    /**
     * Name der Datei
     */
    name?: string;
    /**
     * Dateigröße
     */
    filesize?: number;
    /**
     * Dateiname der Thumbatei
     */
    thumbpfad?: string;
    /**
     * Dateiname der Datei
     */
    pfad?: string;
    /**
     * Dateiname der Datei
     */
    hauptbild?: boolean;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


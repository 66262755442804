import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../api';

@Pipe({
    name: 'toName',
})
export class ToNamePipe implements PipeTransform {
    /**
     * Outputs a {@link User}'s name.
     * @param user The user to transform.
     * @param format The format to output, see examples.
     * @throws Error – if person is not valid.
     * @example
     * const user = {nachname: 'Nachname', vorname: 'Vorname'};
     * pipe.transform(user);           // -> 'Vorname Nachname'
     * pipe.transform(user, 'vorname');  // -> 'Vorname'
     * pipe.transform(user, 'nachname'); // -> 'Nachname'
     * pipe.transform(user, 'VorNachname');   // -> 'Vorname Nachname'
     */
    public static transform(user: User, format: 'vorname' | 'nachname' | 'VorNachname' = 'VorNachname'): string {
        ToNamePipe.validate(user, format);

        switch (format) {
            case 'vorname':
                return user.vorname + '';
            case 'nachname':
                return user.nachname + '';
            default:
                return user.vorname + ' ' + user.nachname;
        }
    }

    /**
     * Checks whether a user has the required attributes.
     * @throws {@link Error} – if user is not valid.
     */
    private static validate(user: User, format: string): void {
        // console.log(user);
        if (!user) {
            throw new Error('[ToNamePipe] Person ist ungültig.');
        }
        if (!user.nachname) {
            throw new Error('[ToNamePipe] Person hat keinen Nachnamen.');
        }
        if (!user.vorname && user.nachname != 'Verein') {
            throw new Error('[ToNamePipe] Person hat keinen Vornamen.');
        }
        switch (format) {
            case 'vorname':
            case 'nachname':
            case 'VorNachname':
                return;
            default:
                throw new Error(`[ToNamePipe] Ungültiges Format '${format}'.`);
        }
    }

    /**
     * @see {@link ToNamePipe.transform}
     */
    public transform(user: User, format?: 'vorname' | 'nachname' | 'VorNachname'): string {
        return ToNamePipe.transform(user, format);
    }
}

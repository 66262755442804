<!-- Anzeige Card -->
<mat-card *ngIf="news && !showEditForm">
    <mat-card-header>
        <mat-card-title>
            <span data-cy="NewsTitel">{{ news.freigegeben ? '' : '(geplant) ' }}{{ news.betreff }}</span>

            <button
                mat-icon-button
                (click)="editEntry()"
                matTooltip="Eintrag editieren"
                *ngIf="showButtons && authService.hasRight('edit news')"
                data-cy="newsEditBtn">
                <mat-icon>edit</mat-icon>
            </button>

            <button
                mat-icon-button
                (click)="deleteEntry()"
                matTooltip="Eintrag löschen"
                *ngIf="showButtons && authService.hasRight('delete news')"
                data-cy="newsDeleteBtn">
                <mat-icon>delete</mat-icon>
            </button>

            <button
                mat-icon-button
                *ngIf="news && showButtons && authService.hasRight('delete news')"
                type="button"
                data-cy="newsAddImage"
                (click)="showImageUpload = !showImageUpload"
                matTooltip="Bilder hinzufügen">
                <mat-icon>add_a_photo</mat-icon>
            </button>
        </mat-card-title>
        <mat-card-subtitle>{{ news.datum | date }}</mat-card-subtitle>
    </mat-card-header>

    <!-- Bilder mit Text -->
    <mat-card-content data-cy="bilderCard">
        <div fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap="20px grid" data-cy="NewsImageRow">
            <div
                fxFlex.xs="50%"
                fxFlex.sm="50%"
                fxFlex.gt-sm="20%"
                style="text-align: center"
                *ngFor="let bild of news.bilder; let i = index"
                [attr.data-cy]="'bild-' + i">
                <img
                    [src]="basePath + '/file/serve/' + (bild.id || '') + '/thumb'"
                    style="vertical-align: middle; border-radius: 2%; width: 100%"
                    (click)="openLightbox(i)" />
            </div>
        </div>
        <p [innerHTML]="news.beschreibung | nl2br | linky"></p>
    </mat-card-content>

    <!-- Image Upload -->
    <mat-card-content
        *ngIf="showImageUpload"
        data-cy="NewsUploadCard"
        fxLayout
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="0">
        <input
            type="file"
            accept="image/*"
            [fileUploadInputFor]="fileUploadQueue"
            #fileUpload
            multiple
            style="display: none" />
        <div [fileUploadInputFor]="fileUploadQueue" (click)="fileUpload.click()" class="upload-drop-zone">
            Just drag and drop pics here
        </div>
        <kkm-file-upload-queue
            #fileUploadQueue
            [fileAlias]="'file'"
            [httpUrl]="fileUrl"
            multiple
            fxFlex
            fxFlex.gt-md="500px">
            <kkm-file-upload
                [file]="file"
                [id]="i"
                *ngFor="let file of fileUploadQueue.files; let i = index"
                (onUpload)="uploadChange($event)" />
        </kkm-file-upload-queue>
    </mat-card-content>
</mat-card>

<!-- neu und Edit -->
<mat-card appearance="outlined" *ngIf="!news || showEditForm">
    <form [formGroup]="newsForm" (ngSubmit)="speichern()">
        <mat-card-header>
            <mat-card-title>
                <span *ngIf="!news">Neuer Eintrag</span>
                <span *ngIf="news">Edit Eintrag</span>
                <button
                    mat-icon-button
                    matTooltip="speichern"
                    type="submit"
                    data-cy="newsSpeichernBtn"
                    [disabled]="!newsForm.valid">
                    <mat-icon>save</mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="abbrechen"
                    (click)="abbrechen()"
                    data-cy="newsAbbrechenBtn"
                    type="button">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="column" fxLayoutGap.lt-sm="0" fxLayoutGap="10px">
                <mat-form-field fxFlex>
                    <mat-label>Betreff</mat-label>
                    <input matInput placeholder="Ex. Betreff" formControlName="betreff" data-cy="NewsBetreffInput" />
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-label>Beschreibung</mat-label>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        placeholder="Ex. Text mit Inhalt"
                        formControlName="beschreibung"
                        data-cy="NewsBeschreibungInput"
                        name="inhalt"></textarea>
                </mat-form-field>

                <mat-checkbox
                    fxFlex.lt-sm="100%"
                    fxFlex.gt-xs="100%"
                    formControlName="freigegeben"
                    data-cy="NewsFreigeben">
                    Eintrag direkt freigeben
                </mat-checkbox>
            </div>

            <div fxLayout="row wrap" fxLayoutGap="10px grid" *ngIf="news && news.bilder" data-cy="bilderCard">
                <div
                    *ngFor="let bild of news.bilder; let i = index"
                    fxFlex.xs="50%"
                    fxFlex.sm="25%"
                    fxFlex.gt-sm="20%"
                    style="text-align: center"
                    [attr.data-cy]="'bild-' + i">
                    <img
                        [src]="basePath + '/file/serve/' + (bild.id || '') + 'thumb'"
                        style="vertical-align: middle; border-radius: 2%; width: 100%"
                        (click)="openLightbox(i)" /><br />

                    <button
                        mat-icon-button
                        (click)="deletePic(bild.id)"
                        matTooltip="Bild löschen"
                        *ngIf="authService.hasRight('delete news')">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <kkm-image-rotate [imageId]="bild.id" />
                </div>
            </div>
        </mat-card-content>
    </form>
</mat-card>

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Boot } from './boot';


export interface Bootsplatz { 
    readonly id: number;
    /**
     * ID des zugehörigen Bootes
     */
    boot_id?: number | null;
    /**
     * Name des Bootsplatzes
     */
    name: string;
    /**
     * Halle des Bootsplatzes
     */
    halle: string;
    /**
     * Seite in der Halle
     */
    seite: string;
    /**
     * Ebene in der Halle
     */
    ebene: number;
    boote?: Boot | null;
    /**
     * MorphTo Class
     */
    morphClass?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Anmeldeprotokoll { 
    /**
     * Task ID
     */
    id: number;
    /**
     * Versuchter Anmeldename
     */
    anmeldename: string;
    /**
     * Erfolg?
     */
    erfolgreich: boolean;
    user_id?: number | null;
    ip: string;
    browser: string;
    os: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, lastValueFrom} from 'rxjs';
import {News, NewsService} from 'src/app/api';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {DeviceScaleService} from 'src/app/services/device-scale.service';

@Component({
    templateUrl: './news-page.component.html',
    styleUrls: ['./news-page.component.scss'],
})
export class NewsPageComponent implements OnInit, OnDestroy {
    newsListe: News[] = [];

    public newEntry = false;
    public newEintrag: News = {betreff: '', datum: new Date()};
    isLoading = true;

    isLoggedIn = false;
    private readonly loginSubscription: Subscription;

    constructor(
        public authService: AuthenticationService,
        private newService: NewsService,
        public deviceScaleService: DeviceScaleService
    ) {
        this.loginSubscription = authService
            .asObservable()
            .subscribe((loginStatus: boolean) => (this.isLoggedIn = loginStatus));
    }

    ngOnInit(): void {
        this.initNewslistData();
        this.isLoggedIn = this.authService.isLoggedIn();
    }

    public ngOnDestroy(): void {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }

    async initNewslistData(): Promise<News[]> {
        this.newsListe = await lastValueFrom(this.newService.getAllNews());
        this.isLoading = false;
        return this.newsListe;
    }

    public reloadEntrys(): void {
        this.initNewslistData();
        this.newEntry = false;
    }
}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject9 { 
    /**
     * Empfänger
     */
    empfaenger?: InlineObject9Empfaenger;
    /**
     * Absender
     */
    absender?: InlineObject9Absender;
    /**
     * Betreff
     */
    betreff?: string;
    /**
     * Text
     */
    text?: string;
}
export enum InlineObject9Empfaenger {
    ALLE = 'alle',
    BOOTSINHABER = 'bootsinhaber',
    SCHLUESSELINHABER = 'schluesselinhaber',
    HAUPTMITGLIEDER = 'hauptmitglieder'
};
export enum InlineObject9Absender {
    VORSTAND = 'vorstand',
    _1VORSITZENDER = '1vorsitzender',
    _2VORSITZENDER = '2vorsitzender',
    KASSENWART = 'kassenwart',
    SCHRIFTFUEHRER = 'schriftfuehrer',
    BOOTSHAUSWART = 'bootshauswart',
    JUGENDWART = 'jugendwart',
    SPORTWART = 'sportwart',
    WANDERWART = 'wanderwart',
    WEBMASTER = 'webmaster'
};




/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Buchung } from './buchung';
import { User } from './user';
import { Datei } from './datei';
import { Belegtyp } from './belegtyp';


export interface Beleg { 
    id: number;
    belegnummer: string;
    /**
     * Belegdatum
     */
    belegdatum: Date;
    belegtyp: Belegtyp;
    empfaenger?: string;
    /**
     * Id des Bankkontos
     */
    betrag: number;
    /**
     * Id des Erfassers
     */
    user_id?: number | null;
    user?: User;
    /**
     * Verknüpfte Buchungen
     */
    buchungen?: Array<Buchung>;
    /**
     * Beleg Bild
     */
    dateien?: Array<Datei>;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ReAktion } from '../model/models';
import { ReGruppe } from '../model/models';
import { User } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RechteServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Mitglied einer Gruppe zuweisen
     * 
     * @param gruppenId Gruppenid
     * @param rechteId RechteId
     */
    addRightToGroup(gruppenId?: string, rechteId?: string, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Mitglied einer Gruppe zuweisen
     * 
     * @param userId Benuterid
     * @param gruppenId Gruppenid
     */
    addUserToGroup(userId?: string, gruppenId?: string, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Liefert alle Gruppe mit ihren Rechten
     * 
     */
    getGruppenRechte(extraHttpRequestParams?: any): Observable<Array<ReGruppe>>;

    /**
     * Liefert alle RechteNamen
     * 
     */
    getRechteliste(extraHttpRequestParams?: any): Observable<Array<ReAktion>>;

    /**
     * Liefert alle Nutzer mit Rechten
     * 
     */
    getUserGruppen(extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * Mitglied aus einer Gruppe entfernen
     * 
     * @param gruppenId Gruppenid
     * @param rechteId RechteId
     */
    removeRightFromGroup(gruppenId?: string, rechteId?: string, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Mitglied aus einer Gruppe entfernen
     * 
     * @param userId Benuterid
     * @param gruppenId Gruppenid
     */
    removeUserFromGroup(userId?: string, gruppenId?: string, extraHttpRequestParams?: any): Observable<User>;

}

<mat-card>
    <mat-card-header>
        <mat-card-title>{{ data.titel }}</mat-card-title>
        <mat-card-subtitle *ngIf="data.datum !== undefined">{{ data.datum | date }}</mat-card-subtitle>
    </mat-card-header>
    <img src="{{ data.image }}" [class.roundImage]="roundImage" />
    <mat-card-content>
        <!-- <span>{{data.titel}}</span> -->
        <p [innerHTML]="data.text | nl2br | linky"></p>
    </mat-card-content>
    <mat-card-actions style="text-align: center">
        <button mat-button *ngIf="data.mehr !== undefined && data.mehr !== ''" routerLink="{{ data.mehr }}">
            mehr
        </button>
    </mat-card-actions>
</mat-card>

export const ALL_WEEK_DAYS: WeekDay[] = [
    {
        displayValue: 'Montag',
        value: 'MONDAY',
        default: true,
    },
    {
        displayValue: 'Dienstag',
        value: 'TUESDAY',
        default: true,
    },
    {
        displayValue: 'Mittwoch',
        value: 'WEDNESDAY',
        default: true,
    },
    {
        displayValue: 'Donnerstag',
        value: 'THURSDAY',
        default: true,
    },
    {
        displayValue: 'Freitag',
        value: 'FRIDAY',
        default: true,
    },
    {
        displayValue: 'Samstag',
        value: 'SATURDAY',
        default: true,
    },
    {
        displayValue: 'Sonntag',
        value: 'SUNDAY',
        default: true,
    },
];

export interface WeekDay {
    displayValue: string;
    value: string;
    default: boolean;
}

<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%"
    data-cy="VorstandPage">
    <mat-card>
        <mat-card-header><mat-card-title>Der Vorstand</mat-card-title></mat-card-header>
        <mat-card-content>
            <table style="margin-left: auto; margin-right: auto" width="100%">
                <tr>
                    <td width="50%">
                        <b>{{ vorstand.ersterVorsitzender! | toName }}</b
                        ><br />
                        1. Vorsitzender<br />
                        Tel. {{ vorstand.ersterVorsitzender!.telefon }}<br />
                        E-Mail: {{ vorstand.ersterVorsitzender!.email }}
                    </td>
                    <td width="50%">
                        <b>{{ vorstand.zweiterVorsitzender! | toName }}</b
                        ><br />
                        2. Vorsitzender<br />
                        E-Mail: {{ vorstand.zweiterVorsitzender!.email }}
                    </td>
                </tr>
            </table>
            <br />
            <table style="margin-left: auto; margin-right: auto" width="100%">
                <tr>
                    <td width="50%">
                        <b>{{ vorstand.kassenwart! | toName }}</b
                        ><br />
                        Kassenwart<br />
                        E-Mail: {{ vorstand.kassenwart!.email }}
                    </td>
                    <td width="50%">
                        <b>{{ vorstand.schriftfuehrer! | toName }}</b
                        ><br />
                        Schriftführer<br />
                        E-Mail: {{ vorstand.schriftfuehrer!.email }}
                    </td>
                </tr>
            </table>
            <br />
            <table style="margin-left: auto; margin-right: auto" width="100%">
                <tr>
                    <td width="50%">
                        <b>{{ vorstand.bootshauswart! | toName }}</b
                        ><br />
                        Bootshauswart<br />
                        E-Mail: {{ vorstand.bootshauswart!.email }}
                    </td>
                    <td width="50%"></td>
                </tr>
            </table>
            <br />
            <table style="margin-left: auto; margin-right: auto" width="100%">
                <tr>
                    <td width="50%">
                        <b>{{ vorstand.wanderwart! | toName }}</b
                        ><br />
                        Wanderwart<br />
                        Tel. {{ vorstand.wanderwart!.telefon }}<br />
                        E-Mail: {{ vorstand.wanderwart!.email }}
                    </td>
                    <td width="50%">
                        <b>{{ vorstand.sportwart! | toName }}</b
                        ><br />
                        Sportwart<br />
                        E-Mail: {{ vorstand.sportwart!.email }}
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <hr />
            <h2>weitere Kontakte</h2>
            <table style="margin-left: auto; margin-right: auto" width="100%">
                <tr>
                    <td width="50%">
                        <b>{{ vorstand.webbeauftragter! | toName }}</b
                        ><br />
                        Webbeauftragter<br />
                        E-Mail: {{ vorstand.webbeauftragter!.email }}
                    </td>
                    <td width="50%">
                        <!--Leer-->
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

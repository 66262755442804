import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {CalendarEvent, CalendarView} from 'angular-calendar';
import {isSameDay, isSameMonth} from 'date-fns';
import {Subscription, lastValueFrom} from 'rxjs';
import {Terminart} from 'src/app/api';
import {TermineService} from 'src/app/api/api/termine.service';
import {Termin} from 'src/app/api/model/termin';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CopyPasteService} from 'src/app/services/copyPaste.service';
import {DeviceScaleService} from 'src/app/services/device-scale.service';

//  Kalender Doku
// https://mattlewis92.github.io/angular-calendar/#/kitchen-sink

@Component({
    templateUrl: './kalender-page.component.html',
    styleUrls: ['./kalender-page.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class KalenderPageComponent implements OnInit, OnDestroy {
    isLoggedIn = false;
    isLoading = true;
    private readonly loginSubscription: Subscription;

    colors: any = {
        red: {
            primary: '#ad2121',
            secondary: '#FAE3E3',
        },
        blue: {
            // Intern
            primary: '#1e90ff',
            secondary: '#D1E8FF',
        },
        yellow: {
            // Extern
            primary: '#e3bc08',
            secondary: '#FDF1BA',
        },
        KKM: {
            // Intern blau
            primary: '#1e90ff',
            secondary: '#D1E8FF',
        },
        Extern: {
            // Extern gelb
            primary: '#e3bc08',
            secondary: '#FDF1BA',
        },
        Raumvermietung: {
            primary: '#ad2121',
            secondary: '#FAE3E3',
        },
    };

    /** Statusvairable für den Ansicht Button */
    Kalenderansicht = 'Liste';

    view: CalendarView = CalendarView.Day;
    CalendarView = CalendarView;
    viewDate: Date = new Date();
    events: CalendarEvent[] = [];
    activeDayIsOpen = false;
    aktYear = new Date().getFullYear();
    heute = new Date();

    minKalenderDate = new Date('01/01/2000');
    maxKalenderDate = new Date('01/01/2031');

    // Listen Tabelle
    dataSource: Termin[] = [];
    termine: Termin[] = [];
    columnsToDisplay = ['datum', 'betreff', 'expand'];
    columnsToDisplayWithExpand = [...this.columnsToDisplay];
    expandedElement: Termin | null = null;

    showTerminForm = false;
    editTermin: Termin | undefined;

    constructor(
        public authService: AuthenticationService,
        private terminService: TermineService,
        protected copyPasteService: CopyPasteService,
        public deviceScaleService: DeviceScaleService
    ) {
        this.loginSubscription = authService.asObservable().subscribe((loginStatus: boolean) => {
            this.isLoggedIn = loginStatus;
            this.setTableColumns();
        });
    }

    ngOnInit(): void {
        this.initTerminlistData();
        this.isLoggedIn = this.authService.isLoggedIn();
        this.setTableColumns();
    }

    setTableColumns() {
        if (this.deviceScaleService.isMobile()) {
            this.columnsToDisplay = ['mobile', 'expand'];
        }

        if (this.isLoggedIn) {
            if (this.deviceScaleService.isMobile()) {
                this.columnsToDisplayWithExpand = [...this.columnsToDisplay, 'buttonsMobile'];
            } else {
                this.columnsToDisplayWithExpand = [...this.columnsToDisplay, 'buttons'];
            }
        } else {
            this.columnsToDisplayWithExpand = [...this.columnsToDisplay];
        }
    }

    public ngOnDestroy(): void {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }

    async initTerminlistData(): Promise<Termin[]> {
        this.termine = await lastValueFrom(this.terminService.getAllTermine(this.aktYear));

        const events: CalendarEvent[] = [];
        this.termine.forEach(termin => {
            const event: CalendarEvent = {
                start: new Date(termin.datum),
                title: termin.betreff,
                color: this.colors[termin.terminart || Terminart.KKM],
                allDay: false,
                meta: termin,
            };
            if (termin.datum_bis != undefined) {
                event.end = new Date(termin.datum_bis);
            }
            events.push(event);
        });
        this.isLoading = false;
        this.events = events;
        this.dataSource = this.termine;
        return this.termine;
    }

    setView(view: CalendarView): void {
        this.view = view;
        switch (view) {
            case CalendarView.Day:
                this.Kalenderansicht = 'Liste';
                break;
            case CalendarView.Month:
                this.Kalenderansicht = 'Month';
                break;
        }
    }

    viewDateChange(): void {
        this.closeOpenMonthViewDay();
        if (this.viewDate.getFullYear() != this.aktYear) {
            this.aktYear = this.viewDate.getFullYear();
            this.initTerminlistData();
        }
    }

    closeOpenMonthViewDay(): void {
        this.activeDayIsOpen = false;
    }

    // Info Zeile für Tag öffnen
    dayClicked({date, events}: {date: Date; events: CalendarEvent[]}): void {
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    // Von Monatsansicht zu Liste für Details wechseln
    handleEvent(action: string, event: CalendarEvent): void {
        this.setView(CalendarView.Day);
        this.expandedElement = event.meta;
        this.scroll('#termin-' + event.meta.id);
    }

    scroll(id: string) {
        console.log(`scrolling to ${id}`);
        // const el = document.getElementById(id);
        // el?.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            document.querySelector(id)?.scrollIntoView({behavior: 'smooth', block: 'center'});
        }, 500);
    }

    newKalenderEntry(): void {
        this.showTerminForm = true;
        this.editTermin = undefined;
    }

    editKalenderEntry(termin: Termin): void {
        this.showTerminForm = true;
        this.editTermin = termin;
    }

    changeKalenderEntry(changedTermin: Termin) {
        this.showTerminForm = false;
        this.initTerminlistData();
    }

    deleteKalenderEntry(termin: Termin): void {
        if (termin.id) {
            lastValueFrom(this.terminService.deleteTermin(termin.id)).then(() => {
                this.initTerminlistData();
                // this.abbrechen();
            });
        }
    }
}

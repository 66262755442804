import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'kkm-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%"
    data-cy="WintertrainingPage">
    <mat-card fxFlex>
        <mat-card-header>
            <mat-card-title>Wintertraining</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>
                In der Wintersaison zwischen November und Ende März paddeln nur einige Unentwegte. Die sportlichen
                Aktivitäten verlagern sich nach drinnen:
            </p>
            <p>
                Mittwochs ab 19:30 Uhr besteht die Möglichkeit zum Kraftausdauertraining in der Turnhalle des FEG
                Mühlheim. Das Training ist ein Gemeinschaftsangebot des benachbarten MRV, des RC-Adler und des KKM.
                Schwerpunkt liegt auf der Kräftigung der Rumpfmuskulatur – das A und O für effizienten und gesunden
                Paddelsport.
            </p>
            <p>
                Konkurrierend dazu treffen wir uns zum Kenterrollentraining im Hallenbad. Wir haben die Möglichkeit,
                Mittwochs das Schwimmbad des Landessportbundes in Frankfurt gemeinsam mit anderen Vereinen der Region zu
                nutzen. Das Rollentraining bedarf der Voranmeldung und wird über eine Chat-Gruppe organisiert.
            </p>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    public static transform(value: string, args: any[] = []): string {
        const limit = args.length > 0 ? parseInt(args[0], 10) : 200;
        const trail = args.length > 1 ? args[1] : '...';
        return value.length > limit ? value.substring(0, limit) + trail : value;
    }

    public transform(value: string, args?: any[]): string {
        return TruncatePipe.transform(value, args);
    }
}

export * from './aufgaben.service';
import { AufgabenService } from './aufgaben.service';
export * from './aufgaben.serviceInterface'
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './auth.serviceInterface'
export * from './bank.service';
import { BankService } from './bank.service';
export * from './bank.serviceInterface'
export * from './beitaege.service';
import { BeitaegeService } from './beitaege.service';
export * from './beitaege.serviceInterface'
export * from './boot.service';
import { BootService } from './boot.service';
export * from './boot.serviceInterface'
export * from './bootplatz.service';
import { BootplatzService } from './bootplatz.service';
export * from './bootplatz.serviceInterface'
export * from './buchhaltung.service';
import { BuchhaltungService } from './buchhaltung.service';
export * from './buchhaltung.serviceInterface'
export * from './dateiablage.service';
import { DateiablageService } from './dateiablage.service';
export * from './dateiablage.serviceInterface'
export * from './dev.service';
import { DevService } from './dev.service';
export * from './dev.serviceInterface'
export * from './file.service';
import { FileService } from './file.service';
export * from './file.serviceInterface'
export * from './mail.service';
import { MailService } from './mail.service';
export * from './mail.serviceInterface'
export * from './mailapi.service';
import { MailapiService } from './mailapi.service';
export * from './mailapi.serviceInterface'
export * from './news.service';
import { NewsService } from './news.service';
export * from './news.serviceInterface'
export * from './protokolle.service';
import { ProtokolleService } from './protokolle.service';
export * from './protokolle.serviceInterface'
export * from './rechte.service';
import { RechteService } from './rechte.service';
export * from './rechte.serviceInterface'
export * from './swBrett.service';
import { SwBrettService } from './swBrett.service';
export * from './swBrett.serviceInterface'
export * from './termine.service';
import { TermineService } from './termine.service';
export * from './termine.serviceInterface'
export * from './user.service';
import { UserService } from './user.service';
export * from './user.serviceInterface'
export * from './util.service';
import { UtilService } from './util.service';
export * from './util.serviceInterface'
export * from './verein.service';
import { VereinService } from './verein.service';
export * from './verein.serviceInterface'
export const APIS = [AufgabenService, AuthService, BankService, BeitaegeService, BootService, BootplatzService, BuchhaltungService, DateiablageService, DevService, FileService, MailService, MailapiService, NewsService, ProtokolleService, RechteService, SwBrettService, TermineService, UserService, UtilService, VereinService];

import {Component, OnInit} from '@angular/core';
import {lastValueFrom, combineLatestWith} from 'rxjs';
import {User, Verein} from 'src/app/api';
import {VereinService} from 'src/app/api/api/verein.service';
import {ToNamePipe} from 'src/app/pipes/to-name.pipe';

@Component({
    selector: 'kkm-impressum',
    templateUrl: './impressum.component.html',
    styleUrls: ['./impressum.component.scss'],
})
export class ImpressumComponent implements OnInit {
    public verein!: Verein;

    // Vorstandswechsel
    vorstand = {
        Vorsitzender1: 'Joachim Trach',
        Vorsitzender2: 'Stefan Weber',
    };

    constructor(private readonly vereinsService: VereinService) {}

    ngOnInit(): void {
        this.initVereinsData();
    }

    async initVereinsData(): Promise<Verein> {
        const verein$ = this.vereinsService.getVerein();
        const vorstand$ = this.vereinsService.getVorstand();

        verein$.pipe(combineLatestWith(vorstand$)).subscribe(([verein, vorstand]) => {
            this.verein = verein;
            this.vorstand = {
                Vorsitzender1: ToNamePipe.transform(vorstand.ersterVorsitzender),
                Vorsitzender2: ToNamePipe.transform(vorstand.zweiterVorsitzender),
            };
        });

        return this.verein;
    }
}

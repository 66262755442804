/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Bankdaten { 
    readonly id?: number;
    /**
     * Id des Eigentümers
     */
    user_id?: number;
    /**
     * IBAN
     */
    iban?: string;
    /**
     * BIC
     */
    bic?: string;
    /**
     * Bankname
     */
    bank?: string;
    /**
     * Abweichender Kontoinhaber
     */
    kontoinhaber?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


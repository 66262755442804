/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { SwBrett } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface SwBrettServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Edit SwBrett Entry
     * 
     * @param id ID des Eintrags
     */
    archiveSwEntry(id: number, extraHttpRequestParams?: any): Observable<SwBrett>;

    /**
     * Edit SwBrett Entry
     * 
     * @param id ID des Eintrags
     */
    deleteSwEntry(id: number, extraHttpRequestParams?: any): Observable<SwBrett>;

    /**
     * Edit SwBrett Entry
     * 
     * @param id ID des Eintrags
     * @param swBrett Ändern eines Eintrags
     */
    editSwEntry(id: number, swBrett: SwBrett, extraHttpRequestParams?: any): Observable<SwBrett>;

    /**
     * Liste aller archivierten Schwarzen Brett Einträge
     * 
     */
    getArchiveEntries(extraHttpRequestParams?: any): Observable<Array<SwBrett>>;

    /**
     * Liste aller aktiven Schwarzen Brett Einträge
     * 
     */
    getEntries(extraHttpRequestParams?: any): Observable<Array<SwBrett>>;

    /**
     * Neuen SW Eintrag anlegen
     * 
     * @param swBrett Anlegen eines neuen SW Eintrages
     */
    newSwEntry(swBrett: SwBrett, extraHttpRequestParams?: any): Observable<SwBrett>;

}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'kkm-datenschutz',
    templateUrl: './datenschutz.component.html',
    styleUrls: ['./datenschutz.component.scss'],
})
export class DatenschutzComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

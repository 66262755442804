import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ImageRotateComponent} from './components/image-rotate/image-rotate.component';
import {VereinsbeitraegeComponent} from './components/vereinsbeitraege/vereinsbeitraege.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './material.module';
import {ButtonMenueComponent} from './components/button-menue/button-menue.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, MaterialModule],
    declarations: [ImageRotateComponent, VereinsbeitraegeComponent, ButtonMenueComponent],
    exports: [ImageRotateComponent, VereinsbeitraegeComponent, ButtonMenueComponent],
})
export class BasicModule {}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Datei } from './datei';


export interface Ordner { 
    readonly id: number;
    /**
     * Name des Ordners
     */
    name: string;
    /**
     * Name des Parent Ordners
     */
    ordner_id?: number;
    /**
     * Betreff des Termins
     */
    rechte_lesen?: string;
    /**
     * Betreff des Termins
     */
    rechte_schreiben?: string;
    /**
     * Dateien im Ordner
     */
    dateien?: Array<Datei>;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


import {Injectable} from '@angular/core';
import {Boot, BootService, Bootsart, Bootsklasse, Termin} from '../api';

@Injectable({
    providedIn: 'root',
})
export class CopyPasteService {
    private copyPasteMaterial: Boot | undefined;
    private copyPasteTermin: Termin | undefined;

    constructor() {}

    copyMaterial(material: Boot) {
        this.copyPasteMaterial = material;
        this.copyPasteMaterial.id = -1;
    }

    pasteMaterial(): Boot {
        if (this.copyPasteMaterial) return this.copyPasteMaterial;
        else return {};
    }

    isMaterialPastePossible(): boolean {
        return this.copyPasteMaterial !== undefined;
    }

    copyEvent(event: Termin) {
        this.copyPasteTermin = event;
        // this.copyPasteTermin.id = -1;
    }

    pasteEvent(): Termin {
        if (this.copyPasteTermin) return this.copyPasteTermin;
        else return {betreff: '', datum: new Date()};
    }

    isEventPastePossible(): boolean {
        return this.copyPasteTermin !== undefined;
    }
}

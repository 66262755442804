import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {DeviceEnum} from '../model/deviceEnum';

/*
  Device Breakpoint list:

  xs:     (min-width: 0px) and (max-width: 599px)
  sm:     (min-width: 600px) and (max-width: 959px)
  md:     (min-width: 960px) and (max-width: 1279px)
  lg:     (min-width: 1280px) and (max-width: 1919px)
  xl:     (min-width: 1920px) and (max-width: 5000px)
*/

/**
 * service for device and screen size detection
 * This Service should be used whenever elements have to react to screen size changes.
 */
@Injectable({
    providedIn: 'root',
})
export class DeviceScaleService {
    private currDevice: BehaviorSubject<DeviceEnum>;

    constructor(public media: MediaObserver) {
        this.currDevice = new BehaviorSubject<DeviceEnum>(DeviceEnum.None);
        media.asObservable().subscribe((changes: MediaChange[]) => {
            changes.forEach(change => {
                switch (change.mqAlias) {
                    case 'xs':
                        this.currDevice.next(DeviceEnum.Mobile);
                        break;
                    case 'sm':
                        this.currDevice.next(DeviceEnum.Tablet);
                        break;
                    case 'md':
                        this.currDevice.next(DeviceEnum.Laptop);
                        break;
                    case 'lg':
                        this.currDevice.next(DeviceEnum.Desktop);
                        break;
                    case 'xl':
                        this.currDevice.next(DeviceEnum.TV);
                        break;
                }
            });
        });
    }

    /**
     * returns an observable device
     */
    public asObservable(): Observable<DeviceEnum> {
        return this.currDevice.asObservable();
    }

    /**
     * returns the current active device
     */
    public getCurrentDeviceScale(): DeviceEnum {
        return this.currDevice.value;
    }

    private isActive(device: DeviceEnum): boolean {
        switch (device) {
            case DeviceEnum.Mobile:
                return this.media.isActive('xs');
            case DeviceEnum.Tablet:
                return this.media.isActive('sm');
            case DeviceEnum.Laptop:
                return this.media.isActive('md');
            case DeviceEnum.Desktop:
                return this.media.isActive('lg');
            case DeviceEnum.TV:
                return this.media.isActive('xl');
            default:
                return false;
        }
    }

    /**
     * Checks if the current screen has mobile or tablet device size.
     * return true if screen size is for mobile or tablet devices
     * return false if screen doesn't match mobile or tablet size.
     */
    public isMobileOrTablet(): boolean {
        return this.isActive(DeviceEnum.Mobile) || this.isActive(DeviceEnum.Tablet);
    }
    /**
     * Checks if the current screen has mobile device size.
     * return true if screen size is for mobile devices
     * return false if screen doesn't match mobile size.
     */
    public isMobile(): boolean {
        return this.isActive(DeviceEnum.Mobile);
    }

    /**
     * Checks if the current screen has tablet device size.
     * return true if screen size is for tablet devices
     * return false if screen doesn't match tablet size.
     */
    public isTablet(): boolean {
        return this.isActive(DeviceEnum.Tablet);
    }

    /**
     * Checks if the current screen has laptop device size.
     * return true if screen size is for laptop devices
     * return false if screen doesn't match laptop size.
     */
    public isLaptop(): boolean {
        return this.isActive(DeviceEnum.Laptop);
    }

    /**
     * Checks if the current screen has desktop device size.
     * return true if screen size is for desktop devices
     * return false if screen doesn't match desktop size.
     */
    public isDesktop(): boolean {
        return this.isActive(DeviceEnum.Desktop);
    }

    /**
     * Checks if the current screen has TV device size.
     * return true if screen size is for TV devices
     * return false if screen doesn't match TV size.
     */
    public isTV(): boolean {
        return this.isActive(DeviceEnum.TV);
    }
}

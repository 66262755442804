/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Terminart } from './terminart';


export interface Termin { 
    readonly id?: number;
    /**
     * Betreff des Termins
     */
    betreff: string;
    /**
     * Text des Termins
     */
    beschreibung?: string;
    /**
     * Datum des Termins
     */
    datum: Date;
    /**
     * Enddatum des Termins
     */
    datum_bis?: Date;
    readonly user_id?: number;
    terminart?: Terminart;
    /**
     * Tage beim Anlegen neuer Termine
     */
    weekDays?: any | null;
    /**
     * MorphTo Class
     */
    morphClass?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Bootsklasse { 
    readonly id?: number;
    /**
     * Name der Bootsklasse für die Abrechnung
     */
    readonly name: string;
    /**
     * Faktor der Bootsklasse für die Abrechnung
     */
    readonly faktor: number;
    /**
     * Beschreibung der Bootsklasse
     */
    readonly beschreibung?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Mitgliedskosten { 
    verbandsbeitrag: number;
    mitgliedsbeitrag: number;
    summeMitglied: number;
    bootskosten: number;
    sonstigekosten: number;
    gesamtkosten: number;
}


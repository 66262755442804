/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Token } from '../model/models';
import { User } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface AuthServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Reset the User Passwort
     * 
     * @param altesPasswort Altes Passwort
     * @param neuesPasswort neues Passwort
     */
    changePW(altesPasswort?: string, neuesPasswort?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Get a JWT via given credentials.
     * 
     * @param username Benutername
     * @param password Passwort für Benutzer
     */
    login(username: string, password: string, extraHttpRequestParams?: any): Observable<Token>;

    /**
     * Log the user out (Invalidate the token).
     * 
     */
    logout(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Get the authenticated User.
     * 
     */
    me(extraHttpRequestParams?: any): Observable<User>;

    /**
     * Get the authenticated User right.
     * 
     */
    myRights(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Refresh a token.
     * 
     */
    refresh(extraHttpRequestParams?: any): Observable<Token>;

    /**
     * Reset the User Passwort
     * 
     * @param username Benutername
     * @param email email für Benutzer
     */
    resetPW(username?: string, email?: string, extraHttpRequestParams?: any): Observable<{}>;

}

<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%"
    data-cy="AnfahrtPage">
    <mat-card fxFlex>
        <mat-card-header>
            <mat-card-title>Anfahrt</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            Wir sind jeden Dienstag an der Paddelstrecke in Mühlheim anzutreffen. Wir freuen uns immer über neue
            Gesichter, also komm einfach mal vorbei!<br />
            <br />
            <div style="text-align: center">
                <div *ngIf="showMap">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d917.8357970598651!2d8.83500685323253!3d50.129405250112384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sde!2sde!4v1678981995494!5m2!1sde!2sde"
                        width="100%"
                        height="450"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <p>
                    Aufgrund der neuen DSGVO ist es uns leider nicht mehr möglich eine Karte hier direckt anzuzeigen.<br />
                    Sie finden uns aber per Google Maps unter
                    <a href="https://goo.gl/maps/1ojgd4SgxtY3X4LZ7" style="color: #0000ff; text-align: left" target="_blank"
                        >"Kanu Klub Mühlheim"</a
                    >
                </p>
                <p>
                    <button mat-raised-button (click)="showMap = true" *ngIf="!showMap">Karte direkt anzeigen</button>
                </p>
            </div>
            <br />
            Unser Bootshaus befindet sich direkt neben dem Bootshaus des Rudervereins Mühlheim.<br />
            Fürs Navi:<br />
            Fährenstraße, 63165 Mühlheim <br />
            GPS: N 50°07'45.76'' , E 8°50'08.30''
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

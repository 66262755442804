/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface Rechnung { 
    readonly id?: number;
    readonly ersteller_id?: number;
    /**
     * Name der Rechnung
     */
    name?: string;
    /**
     * Datum des Termins
     */
    einzugdatum: Date;
    /**
     * User IDs die in der Rechnung enthalten sind
     */
    userIDs: Array<number>;
    /**
     * UserDaten festgeschrieben
     */
    daten?: Array<User>;
    /**
     * Rechnungen verschickt?
     */
    verschickt: boolean;
    /**
     * Lastschrift eingezogen?
     */
    eingezogen: boolean;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


<form [formGroup]="terminForm" (ngSubmit)="speichern()">
    <div data-cy="editEntryArea">
        <mat-card-title>
            {{ termin ? 'Edit' : 'Neuer' }} Termin
            <button
                mat-icon-button
                matTooltip="speichern"
                data-cy="kalenderSpeichernBtn"
                type="submit"
                [disabled]="!terminForm.valid">
                <mat-icon>save</mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Infos kopieren"
                type="button"
                *ngIf="termin"
                (click)="copyEvent()"
                data-cy="eventCopy">
                <mat-icon>content_copy</mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Infos einfügen"
                type="button"
                *ngIf="!termin"
                (click)="pasteEvent()"
                data-cy="eventPaste">
                <mat-icon>content_paste</mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="abbrechen"
                (click)="abbrechen()"
                data-cy="kalenderAbbrechenBtn"
                type="button">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-card-title>
        <div
            fxLayout="row wrap"
            fxLayout.lt-sm="column"
            fxLayoutGap.lt-sm="0"
            fxLayoutAlign="center"
            fxLayoutGap="10px grid"
            data-cy="KalenderEditRow1">
            <mat-form-field fxFlex.xs="100%" fxFlex.gt-xs="60%" data-cy="kalenderDatumAb">
                <mat-label>Termin Datum</mat-label>
                <mat-date-range-input [rangePicker]="picker" data-cy="kalenderDatumAbPicker">
                    <input
                        matStartDate
                        placeholder="Start Datum"
                        formControlName="datum"
                        data-cy="kalenderDatumAbInput" />
                    <input matEndDate placeholder="End Datum" formControlName="datum_bis" data-cy="kalenderDatumBis" />
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-radio-group
                fxFlex.xs="100%"
                fxFlex.gt-xs="40%"
                layout="row"
                formControlName="terminart"
                style="padding-right: 20px"
                data-cy="kalenderTerminart">
                <mat-radio-button
                    color="primary"
                    data-cy="kalenderterminart_id-0"
                    [value]="terminart.KKM"
                    style="padding-right: 20px"
                    >KKM</mat-radio-button
                >
                <mat-radio-button color="primary" data-cy="kalenderterminart_id-1" [value]="terminart.EXTERN"
                    >Externer Veranstalter</mat-radio-button
                >
                <mat-radio-button color="primary" data-cy="kalenderterminart_id-2" [value]="terminart.RAUMVERMIETUNG"
                    >interne Raumvermietung (wird nur intern angezeigt)</mat-radio-button
                >
            </mat-radio-group>

            <mat-form-field data-cy="kalenderBetreff" fxFlex="100%">
                <mat-label>Betreff</mat-label>
                <input matInput placeholder="Ex. Betreff" formControlName="betreff" required />
            </mat-form-field>

            <mat-form-field fxFlex="100%">
                <mat-label>Beschreibung</mat-label>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="2"
                    placeholder="Ex. Text mit Inhalt"
                    formControlName="beschreibung"
                    data-cy="kalenderInhalt"></textarea>
            </mat-form-field>

            <mat-form-field data-cy="wochentageSelect" fxFlex="100%" *ngIf="!termin">
                <mat-select
                    id="entry-dialog-wochentage-input"
                    placeholder="Wochentage *"
                    formControlName="weekDays"
                    #weekDaySelection
                    multiple
                    fxFlex>
                    <!-- eslint-disable-next-line -->
                    <mat-select-trigger>{{ weekDaysToFormFieldText() }}</mat-select-trigger>
                    <mat-option
                        id="entry-dialog-wochentage-day-input"
                        *ngFor="let weekDay of weekDays"
                        [value]="weekDay"
                        >{{ weekDay.displayValue }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>

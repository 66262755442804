import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from 'src/app/api/api/auth.service';

import * as moment from 'moment';
import {Token, User} from '../api';
import {BehaviorSubject, Observable, lastValueFrom} from 'rxjs';
import {ReAktion} from '../api/model/reAktion';
import {environment} from 'src/environments/environment';

const TOKEN_KEY = 'id_token';
const USER_KEY = 'auth-user';
const RIGHTS_KEY = 'user-rights';
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private http: HttpClient, private authService: AuthService) {
        this.loginStatus = new BehaviorSubject<boolean>(false);
    }
    private loginStatus = new BehaviorSubject<boolean>(false);

    /**
     * returns an observable login Status
     */
    public asObservable(): Observable<boolean> {
        return this.loginStatus.asObservable();
    }

    async login(benutzername: string, password: string): Promise<Token> {
        const response = await lastValueFrom(this.authService.login(benutzername, password));
        this.setSession(response);

        const meResponse = await lastValueFrom(this.authService.me());
        localStorage.setItem(USER_KEY, JSON.stringify(meResponse));

        this.loginStatus.next(this.isLoggedIn());
        this.getMyRight();

        return response;
    }

    private setSession(authResult: Token): void {
        const expiresAt = moment().add(authResult.expires_in, 'second');

        localStorage.setItem(TOKEN_KEY, authResult.access_token);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    }

    async refreshLogin(): Promise<void> {
        if (environment.production) {
            const response = await lastValueFrom(this.authService.refresh());
            this.setSession(response);
        }
        this.getMyRight();
    }

    logout(): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem('expires_at');
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(RIGHTS_KEY);

        this.loginStatus.next(this.isLoggedIn());
    }

    public isLoggedIn(): boolean {
        const result = moment().isBefore(this.getExpiration());
        this.loginStatus.next(result);
        // console.log('loggedIn = ' + result + ' ' + this.getExpiration().toString());
        return result;
        // return moment().isBefore(this.getExpiration());
    }

    isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    getExpiration(): any {
        const expiration = localStorage.getItem('expires_at');
        if (expiration == null) {
            return moment().subtract(1, 'day');
        }
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    getToken(): string {
        return localStorage.getItem(TOKEN_KEY) || '';
    }

    async getMyRight(): Promise<void> {
        // Rechte abfragen und in Local Storage ablegen als Liste
        const myRightsResponse: ReAktion[] = await lastValueFrom(this.authService.myRights());
        const rights: string[] = [];

        myRightsResponse.forEach(right => {
            if (right.name !== undefined) {
                rights.push(right.name);
            }
        });
        localStorage.setItem(RIGHTS_KEY, JSON.stringify(rights));
    }

    public hasRight(right: string): boolean {
        const rights: string[] = JSON.parse(localStorage.getItem(RIGHTS_KEY) || '[]');
        return this.isLoggedIn() && rights.includes(right);
    }

    getMe(): User {
        const me: User = JSON.parse(localStorage.getItem(USER_KEY) || '{}');
        return me;
    }
}

<div>
    <!-- Image Carousel -->
    <div style="width: 100%">
        <mat-carousel [hideArrows]="false" [hideIndicators]="false">
            <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image">
            </mat-carousel-slide>
        </mat-carousel>
    </div>

    <div
        fxLayout.gt-md="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="space-evenly stretch"
        fxLayoutGap="10px"
        style="padding: 10px"
        data-cy="sportCardArea">
        <kkm-infocard
            fxFlex
            [roundImage]="true"
            *ngFor="let card of sportbereiche; let i = index"
            [data]="card"
            data-cy="sportCard"></kkm-infocard>
    </div>

    <div style="text-align: center">
        <h2>Aktuelles / Berichte aus dem Vereinsleben</h2>
    </div>

    <div
        fxLayout.gt-md="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="space-evenly stretch"
        fxLayoutGap="10px"
        style="padding: 10px"
        data-cy="newsCardArea">
        <mat-spinner *ngIf="isLoading; else newscards" class="spinner"></mat-spinner>
        <ng-template #newscards>
            <kkm-infocard
            fxFlex
            *ngFor="let card of news.slice(0, 4); let i = index"
            [data]="card"
            [truncate]="true"
            data-cy="newsCard"></kkm-infocard>
        </ng-template>
    </div>
    <!-- <mat-paginator [length]="news.length"
               [pageSize]="pageSize"
               [hidePageSize]=true
               (page)="pageEvent = $event"
               aria-label="Select page">
</mat-paginator> -->
</div>

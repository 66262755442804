/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Datei } from '../model/models';
import { Ordner } from '../model/models';
import { User } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DateiablageServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Delete File
     * 
     * @param id File ID
     */
    deleteDatei(id: number, extraHttpRequestParams?: any): Observable<Datei>;

    /**
     * Delete File
     * 
     * @param id Ordner ID
     */
    deleteOrdner(id: number, extraHttpRequestParams?: any): Observable<Ordner>;

    /**
     * Datei https://github.com/zircote/swagger-php/issues/560
     * 
     * @param id File ID
     */
    getDatei(id: number, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * Liste aller Dateien eines Ordners
     * 
     * @param id ID der Rechnung
     */
    getOrdner(id: number, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Liste aller Ordner
     * 
     */
    getOrdnerliste(extraHttpRequestParams?: any): Observable<Array<Ordner>>;

    /**
     * neuen Ordner erstellen
     * 
     * @param ordner Daten eines neuen Ordners
     */
    newOrdner(ordner: Ordner, extraHttpRequestParams?: any): Observable<Ordner>;

    /**
     * Datei umbenennen
     * 
     * @param id ID des Eintrags
     * @param datei Daten eines neuen Ordners
     */
    updateFile(id: number, datei: Datei, extraHttpRequestParams?: any): Observable<Datei>;

    /**
     * Ordner umbenennen
     * 
     * @param id ID des Eintrags
     * @param ordner Daten eines neuen Ordners
     */
    updateOrdner(id: number, ordner: Ordner, extraHttpRequestParams?: any): Observable<Ordner>;

    /**
     * Neue Datei anlegen
     * 
     * @param folderId ID des Eintrags
     * @param file file to upload
     */
    uploadDatei(folderId: number, file?: Blob, extraHttpRequestParams?: any): Observable<User>;

}

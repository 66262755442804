/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Mailtracking } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface MailapiServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Liste aller Boote
     * 
     */
    getMails(extraHttpRequestParams?: any): Observable<Array<Mailtracking>>;

    /**
     * mark as read
     * 
     * @param token Token
     */
    read(token: string, extraHttpRequestParams?: any): Observable<{}>;

}

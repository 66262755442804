/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Beitragskategorie } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BeitaegeServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Liefert alle Beitragskategorien mit Preishistorie
     * 
     */
    getAllBeitraege(extraHttpRequestParams?: any): Observable<Array<Beitragskategorie>>;

    /**
     * Liefert alle Beitragskategorien mit Preishistorie
     * 
     */
    getBeitraege(extraHttpRequestParams?: any): Observable<Array<Beitragskategorie>>;

}

import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './sommertraining.component.html',
    styleUrls: ['./sommertraining.component.scss'],
})
export class SommertrainingComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Bootsplatz } from './bootsplatz';
import { User } from './user';
import { Aufgabe } from './aufgabe';
import { News } from './news';
import { Termin } from './termin';
import { SwBrett } from './swBrett';
import { Boot } from './boot';


export interface Aenderungsprotokoll { 
    /**
     * Task ID
     */
    id: number;
    user_id: number | null;
    /**
     * Typ
     */
    entity_type?: string;
    entity_id?: number | null;
    entity?: User | Boot | Termin | News | Aufgabe | Bootsplatz | SwBrett;
    /**
     * Array containing changes made to the entity
     */
    changes?: Array<object>;
    user?: User;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


import {Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {lastValueFrom} from 'rxjs';
import {Termin, Terminart, TermineService} from 'src/app/api';
import {ALL_WEEK_DAYS, WeekDay} from 'src/app/model/weekDay';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CopyPasteService} from 'src/app/services/copyPaste.service';
import {DeviceScaleService} from 'src/app/services/device-scale.service';
import {NotificationService} from 'src/app/services/notification/notification.service';

@Component({
    selector: 'kkm-termin-form',
    templateUrl: './termin-form.component.html',
    styleUrls: ['./termin-form.component.scss'],
})
export class TerminFormComponent implements OnInit, OnChanges {
    @Input() termin: Termin | undefined;
    @Output() terminChange = new EventEmitter<Termin>();

    terminForm = new UntypedFormGroup({});
    terminart = Terminart;

    public weekDays: WeekDay[] = ALL_WEEK_DAYS;

    constructor(
        public authService: AuthenticationService,
        private terminService: TermineService,
        protected copyPasteService: CopyPasteService,
        public deviceScaleService: DeviceScaleService,
        private notification: NotificationService
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.termin) {
            this.initForm();
        }
    }

    private initForm() {
        this.terminForm = new FormGroup({
            betreff: new FormControl('', Validators.required),
            beschreibung: new FormControl(''),
            terminart: new FormControl<Terminart>(Terminart.KKM, Validators.required),
            datum: new FormControl<Date | null>(null, Validators.required),
            datum_bis: new FormControl<Date | null>(null),
            weekDays: new FormControl(ALL_WEEK_DAYS.filter(weekDay => weekDay.default)),
        });
        if (this.termin) {
            this.terminForm.patchValue(this.termin);
        }
    }

    abbrechen(): void {
        this.terminChange.emit(undefined);
    }

    speichern(): void {
        if (!this.terminForm.valid) {
            this.notification.error('Es sind nicht alle Felder ausgefüllt');
        }
        if (this.terminForm.valid) {
            const val = this.terminForm.value;
            let newTermin: Termin = {
                betreff: '',
                datum: new Date(),
            };
            newTermin = Object.assign(newTermin, val);

            if (!this.termin) {
                console.log('new');
                newTermin.weekDays = val.weekDays;
                lastValueFrom(this.terminService.newTermin(newTermin)).then(
                    response => {
                        if (response.id) {
                            this.notification.success('Gespeichert');
                            this.terminChange.emit(newTermin);
                        }
                    },
                    response => {
                        this.notification.error(`Beim Speichern ist ein Fehler aufgetreten. ${response.error.message}`);
                    }
                );
            }

            if (this.termin && this.termin.id) {
                console.log('edit');
                lastValueFrom(this.terminService.editTermin(this.termin.id, newTermin)).then(
                    response => {
                        if (response.id) {
                            this.notification.success('Gespeichert');
                            this.terminChange.emit(newTermin);
                        }
                    },
                    response => {
                        this.notification.error(`Beim Speichern ist ein Fehler aufgetreten. ${response.error.message}`);
                    }
                );
            }
        }
    }

    copyEvent() {
        if (this.termin) {
            this.copyPasteService.copyMaterial(this.termin);
            this.notification.info('Infos kopiert');
        }
    }

    pasteEvent() {
        this.terminForm.patchValue(this.copyPasteService.pasteMaterial());
        this.notification.info('Infos eingefügt');
    }

    public weekDaysToFormFieldText(): string {
        const diToDo = ['TUESDAY', 'WEDNESDAY', 'THURSDAY'];
        const moToDo = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY'];
        const moToFr = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
        const moToSa = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        const value = this.terminForm.get('weekDays')?.value;

        if (value.length === 3) {
            for (const item of value) {
                if (diToDo.indexOf(item.value) === -1) {
                    return this.allDaysAsAbbreveation(value);
                }
            }
            return 'Di. - Do.';
        }
        if (value.length === 4) {
            for (const item of value) {
                if (moToDo.indexOf(item.value) === -1) {
                    return this.allDaysAsAbbreveation(value);
                }
            }
            return 'Mo. - Do.';
        }
        if (value.length === 5) {
            for (const item of value) {
                if (moToFr.indexOf(item.value) === -1) {
                    return this.allDaysAsAbbreveation(value);
                }
            }
            return 'Mo. - Fr.';
        }
        if (value.length === 6) {
            for (const item of value) {
                if (moToSa.indexOf(item.value) === -1) {
                    return this.allDaysAsAbbreveation(value);
                }
            }
            return 'Mo. - Sa.';
        }
        if (value.length === 7) {
            return 'Mo. - So.';
        }
        if (value) {
            return this.allDaysAsAbbreveation(value);
        }
        return this.allDaysAsAbbreveation(value);
    }

    private allDaysAsAbbreveation(value: WeekDay[]): string {
        let result = '';
        value.forEach(item => (result += item.displayValue.substr(0, 2) + '., '));
        return result.substr(0, result.length - 2);
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {TruncatePipe} from 'src/app/pipes/truncate.pipe';

@Component({
    selector: 'kkm-infocard',
    templateUrl: './infocard.component.html',
    styleUrls: ['./infocard.component.scss'],
})
export class InfocardComponent implements OnInit {
    @Input() data: {
        image: string;
        titel: string;
        text: string;
        mehr?: string;
        datum?: Date | undefined;
    } = {image: '', titel: '', text: '', mehr: ''};
    @Input() roundImage = false;
    @Input() truncate = false;

    constructor() {}

    ngOnInit(): void {
        if (this.truncate) {
            this.data.text = TruncatePipe.transform(this.data.text);
        }
    }
}

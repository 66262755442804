/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Datei } from '../model/models';
import { News } from '../model/models';
import { User } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FileServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Delete File
     * 
     * @param id File ID
     */
    deleteFile(id: number, extraHttpRequestParams?: any): Observable<News>;

    /**
     * Datei https://github.com/zircote/swagger-php/issues/560
     * 
     * @param id File ID
     */
    getFile(id: number, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * Bild drehen
     * 
     * @param id File ID
     * @param degree Gradzahl
     */
    rotateImage(id: number, degree: string, extraHttpRequestParams?: any): Observable<Datei>;

    /**
     * Neue Datei anlegen
     * 
     * @param type Zieltyp des Eintrags
     * @param id ID des Eintrags
     * @param file file to upload
     */
    uploadFile(type: 'profilbild' | 'termin' | 'news' | 'boot', id: number, file?: Blob, extraHttpRequestParams?: any): Observable<User>;

}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Beitragspreis } from './beitragspreis';


export interface Beitragskategorie { 
    readonly id: number;
    /**
     * Kürzel des Beitrages
     */
    readonly kuerzel: string;
    /**
     * Beitragsname
     */
    readonly name: string;
    /**
     * Zusätzliche Beschreibung
     */
    readonly beschreibung?: string;
    /**
     * Alle Beiträge, ZUkunft und Vergangenheit
     */
    preise?: Array<Beitragspreis>;
    preis: Beitragspreis;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enumeration of Belegtyp: Einnahme, Ausgabe, Mitgliedsbeitrag, Spende
 */
export enum Belegtyp {
    EINNAHME = 'Einnahme',
    AUSGABE = 'Ausgabe',
    MITGLIEDSBEITRAG = 'Mitgliedsbeitrag',
    SPENDE = 'Spende'
};


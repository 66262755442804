import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

export enum NotificationType {
    Neutral = 'nt-neutral',
    Success = 'nt-success',
    Info = 'nt-info',
    Warning = 'nt-warning',
    Error = 'nt-error',
}

/**
 * Additional configuration options that may be passed to the {@link NotificationService}
 * when displaying a notification.
 */
export interface NotificationConfig {
    /**
     * The label for the snackbar action.
     * @see {@link NotificationService.DEFAULT_ACTION}
     */
    action?: undefined | null | string;

    /**
     * Whether to also print to the console.
     */
    console?: undefined | boolean;

    /**
     * Additional configuration options for the snackbar.
     */
    snackBarConfig?: undefined | MatSnackBarConfig;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public static readonly DEFAULT_ACTION = '✖️';
    public static readonly DEFAULT_NOTIFICATION_CONFIG: NotificationConfig = {
        action: NotificationService.DEFAULT_ACTION,
        console: false,
        snackBarConfig: {
            direction: 'ltr',
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: ['notification'],
        },
    };

    public constructor(private snackbar: MatSnackBar) {}

    /**
     * Logs a notification to the console.
     * @param type The type of the notification.
     * @param msg The message to be displayed.
     * @private
     * @static
     */
    private static logToConsole(type: NotificationType, msg: string): void {
        switch (type) {
            case NotificationType.Error:
                console.error(msg);
                break;
            case NotificationType.Warning:
                console.warn(msg);
                break;
            case NotificationType.Info:
                console.info(msg);
                break;
            default:
                console.log(msg);
        }
    }

    /**
     * Displays an error notification.
     * @public
     * @param msg The error message to display.
     * @param config Additional configuration options for the notification.
     */
    public error(msg: string, config?: NotificationConfig): void {
        return this.notify(NotificationType.Error, msg, config);
    }

    /**
     * Displays a warning notification.
     * @public
     * @param msg The warning message to display.
     * @param config Additional configuration options for the notification.
     */
    public warn(msg: string, config?: NotificationConfig): void {
        return this.notify(NotificationType.Warning, msg, config);
    }

    /**
     * Displays an info notification.
     * @public
     * @param msg The info message to display.
     * @param config Additional configuration options for the notification.
     */
    public info(msg: string, config?: NotificationConfig): void {
        return this.notify(NotificationType.Info, msg, config);
    }

    /**
     * Displays a success notification.
     * @public
     * @param msg The success message to display.
     * @param config Additional configuration options for the notification.
     */
    public success(msg: string, config?: NotificationConfig): void {
        return this.notify(NotificationType.Success, msg, config);
    }

    /**
     * Displays a neutral notification.
     * @public
     * @param msg The message to display.
     * @param config Additional configuration options for the notification.
     */
    public neutral(msg: string, config?: NotificationConfig): void {
        return this.notify(NotificationType.Neutral, msg, config);
    }

    /**
     * Displays an error notification.
     * The message depends on the error's status code.
     * @public
     * @param error The error to handle.
     * @param config Additional configuration options for the notification.
     */
    public handleHttpError(error: any, config?: NotificationConfig): void {
        if (!error || !error.status) {
            throw new Error('Error while handling HttpError: invalid error or status');
        }
        let msg: string;

        switch (error.status) {
            case 401:
                msg = 'Bitte melde dich am System an.';
                break;
            case 403:
                msg = 'Du besitzt unzureichende Berechtigungen.';
                break;
            case 404:
                msg = 'Die angeforderte Ressource konnte nicht gefunden werden.';
                break;
            case 500:
                msg = 'Bei der Verarbeitung der Anfrage ist ein Fehler aufgetreten, versuche es später erneut.';
                break;
            case 503:
                msg = 'Der Server ist temporär nicht verfügbar, versuche es später erneut.';
                break;
            default:
                msg = 'Bei der Anfrage ist ein Fehler aufgetreten.';
        }

        this.error(msg, config);
    }

    /**
     * Displays a Snackbar.
     * @param type The type of the notification.
     * @param msg The message to display.
     * @param config Additional configuration options for the notification.
     * @private
     */
    private notify(type: NotificationType, msg: string, config?: NotificationConfig): void {
        if (!config) {
            config = NotificationService.DEFAULT_NOTIFICATION_CONFIG;
        } else {
            if (config.action === undefined) {
                config.action = NotificationService.DEFAULT_ACTION;
            }
            if (config.console) {
                NotificationService.logToConsole(type, msg);
            }
            if (!config.snackBarConfig) {
                config.snackBarConfig = NotificationService.DEFAULT_NOTIFICATION_CONFIG.snackBarConfig;
            }
        }
        if (config.snackBarConfig && config.action) {
            config.snackBarConfig.panelClass = Array.from(config.snackBarConfig.panelClass || []);
            config.snackBarConfig.panelClass.push('notification', type.toString());

            this.snackbar.open(msg, config.action, config.snackBarConfig);
        }
    }
}

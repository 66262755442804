/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Boot } from '../model/models';
import { Bootsplatz } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BootplatzServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Edit Bootsplatz
     * 
     * @param id ID des Bootsplatzes
     * @param boot Ändern eines Bootes
     */
    editBootsplatz(id: number, boot: Boot, extraHttpRequestParams?: any): Observable<Boot>;

    /**
     * Infos aller Bootsplätze
     * 
     */
    getBootsplatzInfos(extraHttpRequestParams?: any): Observable<Array<Bootsplatz>>;

}

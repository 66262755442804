import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './wintertraining.component.html',
    styleUrls: ['./wintertraining.component.scss'],
})
export class WintertrainingComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

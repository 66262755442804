/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface Schluessel { 
    readonly id?: number;
    /**
     * Schlüsselnummer
     */
    user_id?: string;
    /**
     * Schlüsselnummer
     */
    schluesselnummer?: string;
    /**
     * Bereich Bootshaus
     */
    bootshaus?: boolean;
    /**
     * Bereich Privatboote
     */
    privatboote?: boolean;
    /**
     * Bereich Werkstatt
     */
    werkstatt?: boolean;
    user?: User;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


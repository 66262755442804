/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Buchung } from './buchung';


export interface InlineResponse2001 { 
    buchungen: Array<Buchung>;
    /**
     * Saldo vor dem Startdatum
     */
    startSaldo: number;
    /**
     * Saldo vor dem Startdatum nach Konten
     */
    startSaldoNachKonten: { [key: string]: number; };
}


/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface Kassenpruefung { 
    readonly id: number;
    /**
     * Buchungsdatum
     */
    pruefdatum?: Date | null;
    /**
     * Buchungsdatum
     */
    pruefzeitraumStart: Date;
    /**
     * Buchungsdatum
     */
    pruefzeitraumEnde: Date;
    /**
     * Id des Erfassers
     */
    kassenwart_id?: number | null;
    /**
     * Id des Erfassers
     */
    pruefer1_id?: number | null;
    /**
     * Id des Erfassers
     */
    pruefer2_id?: number | null;
    kassenwart?: User;
    pruefer1?: User;
    pruefer2?: User;
    /**
     * ist die Buchung abgeschlossen?
     */
    abgeschlossen?: boolean | null;
    beschreibung?: string;
    user?: User;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


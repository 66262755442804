import {Component, OnInit} from '@angular/core';
import {BeitragService} from 'src/app/services/beitrag.service';

@Component({
    selector: 'kkm-vereinsbeitraege',
    templateUrl: './vereinsbeitraege.component.html',
    styleUrls: ['./vereinsbeitraege.component.scss'],
})
export class VereinsbeitraegeComponent implements OnInit {
    constructor(protected beitragService: BeitragService) {}

    ngOnInit(): void {}
}

/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { AufgabeStatus } from './aufgabeStatus';


export interface Aufgabe { 
    /**
     * Task ID
     */
    id: number;
    /**
     * Aufgaben Titel
     */
    titel: string;
    /**
     * Aufgaben Beschreibung
     */
    beschreibung: string;
    status: AufgabeStatus;
    /**
     * Fälligkeitsdatum
     */
    faelligkeitsdatum?: Date | null;
    /**
     * Zugewiesener Verantwortlicher
     */
    verantwortlicher_id?: number | null;
    /**
     * Ersteller
     */
    ersteller_id?: number | null;
    /**
     * Whether the task is publicly visible or not
     */
    viewEveryone: boolean;
    verantwortlicher?: User;
    ersteller?: User;
    /**
     * MorphTo Class
     */
    morphClass?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {NotificationService} from 'src/app/services/notification/notification.service';

@Component({
    selector: 'kkm-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        private notification: NotificationService
    ) {}

    loginForm = new FormGroup({
        benutzername: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
        password: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });

    returnUrl = '';

    login(): void {
        const val = this.loginForm.value;

        if (this.loginForm.valid && val.benutzername && val.password) {
            this.authService
                .login(val.benutzername, val.password)
                .then(() => {
                    this.router.navigateByUrl(this.returnUrl);
                })
                .catch(() => {
                    this.notification.error('Der Benutzer ist nicht vorhanden.');
                });
        }
    }

    ngOnInit(): void {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/mitgliederbereich';
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './anfahrt.component.html',
    styleUrls: ['./anfahrt.component.scss'],
})
export class AnfahrtComponent implements OnInit {
    constructor() {}

    showMap = false;

    ngOnInit(): void {}
}

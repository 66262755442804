/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Datei } from './datei';


export interface News { 
    readonly id?: number;
    /**
     * Betreff des Newsartikels
     */
    betreff: string;
    /**
     * Text des Newsartikels
     */
    beschreibung?: string;
    /**
     * Datum des Newsartikels
     */
    datum: Date;
    readonly user_id?: number;
    /**
     * Ist der Eintrag freigegeben
     */
    freigegeben?: boolean;
    /**
     * Bilder
     */
    bilder?: Array<Datei>;
    /**
     * MorphTo Class
     */
    morphClass?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


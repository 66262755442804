import {Component, OnInit} from '@angular/core';
import {User, VereinService} from 'src/app/api';

@Component({
    selector: 'kkm-vorstand',
    templateUrl: './vorstand.component.html',
    styleUrls: ['./vorstand.component.scss'],
})
export class VorstandComponent implements OnInit {
    public vorstand: {
        ersterVorsitzender?: User;
        zweiterVorsitzender?: User;
        kassenwart?: User;
        schriftfuehrer?: User;
        bootshauswart?: User;
        wanderwart?: User;
        sportwart?: User;
        webbeauftragter?: User;
    } = {};

    constructor(private vereinsService: VereinService) {}

    ngOnInit(): void {
        this.initVorstandData();
    }

    async initVorstandData(): Promise<{
        ersterVorsitzender?: User;
        zweiterVorsitzender?: User;
        kassenwart?: User;
        schriftfuehrer?: User;
        bootshauswart?: User;
        wanderwart?: User;
        sportwart?: User;
        webbeauftragter?: User;
    }> {
        this.vorstand = await this.vereinsService.getVorstand().toPromise();
        return this.vorstand;
    }
}

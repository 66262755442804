/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Termin } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface TermineServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Delete termin Entry
     * 
     * @param id ID des Eintrags
     */
    deleteTermin(id: number, extraHttpRequestParams?: any): Observable<Termin>;

    /**
     * Edit termin Entry
     * 
     * @param id ID des Eintrags
     * @param termin Ändern eines Eintrags
     */
    editTermin(id: number, termin: Termin, extraHttpRequestParams?: any): Observable<Termin>;

    /**
     * Liefert alle Termine des gefragten Jahres
     * 
     * @param year Jahr der Liste
     */
    getAllTermine(year: number, extraHttpRequestParams?: any): Observable<Array<Termin>>;

    /**
     * Neuen termin anlegen
     * 
     * @param termin Anlegen eines neuen Termin Beitrages
     */
    newTermin(termin: Termin, extraHttpRequestParams?: any): Observable<Termin>;

}

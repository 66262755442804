import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'kkm-bootshaus',
    templateUrl: './bootshaus.component.html',
    styleUrls: ['./bootshaus.component.scss'],
})
export class BootshausComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

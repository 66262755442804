<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="400px"
    fxFlex.xs="100%"
    data-cy="loginPage">
    <mat-card data-cy="loginCard" fxFlex>
        <mat-card-header>
            <mat-card-title>Mitgliederbereich Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="login()" fxFlex>
                <br />
                <p>
                    <mat-form-field fxFlex>
                        <mat-label>Benutzername</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="benutzername"
                            id="username"
                            name="username"
                            autocomplete="username"
                            data-cy="loginBenutzername" />
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field fxFlex>
                        <mat-label>Passwort</mat-label>
                        <input
                            matInput
                            type="password"
                            id="current-password"
                            name="current-password"
                            autocomplete="current-password"
                            formControlName="password"
                            data-cy="loginPasswort" />
                    </mat-form-field>
                </p>

                <button mat-raised-button type="submit" data-cy="loginBtn">Login</button><br /><br />
                <a routerLink="/passwortVergessen">Passwort vergessen</a>
            </form>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

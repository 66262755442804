/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { News } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface NewsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Delete News Entry
     * 
     * @param id ID des Eintrags
     */
    deleteNewsEntry(id: number, extraHttpRequestParams?: any): Observable<News>;

    /**
     * Edit News Entry
     * 
     * @param id ID des Eintrags
     * @param news Ändern eines Eintrags
     */
    editNewsEntry(id: number, news: News, extraHttpRequestParams?: any): Observable<News>;

    /**
     * Liefert alle Newsbeiträge
     * 
     */
    getAllNews(extraHttpRequestParams?: any): Observable<Array<News>>;

    /**
     * Neuen Newsbeitrag anlegen
     * 
     * @param news Anlegen eines neuen News Beitrages
     */
    newNewsEntry(news: News, extraHttpRequestParams?: any): Observable<News>;

}

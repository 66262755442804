/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface SwBrett { 
    readonly id?: number;
    /**
     * Bei Antworten bezug zum Erstbeitrag
     */
    bezug_id?: number | null;
    /**
     * Id des Benutzers
     */
    user_id?: number;
    /**
     * Betreff des Themas
     */
    betreff?: string;
    /**
     * Text des Eintrags
     */
    inhalt?: string;
    /**
     * Datum des Eintrags
     */
    datum?: Date;
    /**
     * Archiviert ?
     */
    archiv?: boolean;
    /**
     * Antworten
     */
    antworten?: Array<SwBrett>;
    author?: User;
    /**
     * MorphTo Class
     */
    morphClass?: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at?: string;
    /**
     * Last update timestamp
     */
    readonly updated_at?: string;
    /**
     * Soft delete timestamp
     */
    readonly deleted_at?: string;
}


<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="400px"
    fxFlex.xs="100%"
    data-cy="loginPage">

    <mat-card data-cy="loginCard" fxFlex>
        <mat-card-header>
            <mat-card-title>Passwort vergessen</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="pwForm" (ngSubmit)="vergessen()" fxFlex>
                <p>
                    <mat-form-field fxFlex>
                        <mat-label>Benutzername</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="benutzername"
                            autocomplete="username"
                            data-cy="vergessenBenutzername" />
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field fxFlex>
                        <mat-label>Email</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="email"
                            autocomplete="email"
                            data-cy="vergessenEmail" />
                    </mat-form-field>
                </p>

                <button mat-raised-button type="submit" data-cy="vergessenBtn">Absenden</button>
            </form>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

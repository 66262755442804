/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Schluessel } from '../model/models';
import { User } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Delete User Entry
     * 
     * @param id ID des Eintrags
     */
    deleteUser(id: number, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Edit User Entry
     * 
     * @param id ID des Eintrags
     * @param user Ändern eines Eintrags
     */
    editUser(id: number, user: User, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Edit User Entry
     * 
     * @param id ID des Eintrags
     * @param user Ändern eines Eintrags
     */
    editUserKoords(id: number, user: User, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Liste aller aktuellen Mitglieder
     * 
     */
    getAllActiveFullUser(extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * Liste aller aktuellen Mitglieder
     * 
     */
    getAllActiveUser(extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * Liste aller ehemaligen Mitglieder
     * 
     */
    getAllArchiveUser(extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * Daten aller Vollmitglieder mit Boot, Familie und Anrede
     * 
     */
    getAllFullUser(extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * 
     * 
     */
    getAllUser(extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * 
     * 
     */
    getKeyList(extraHttpRequestParams?: any): Observable<Array<Schluessel>>;

    /**
     * Daten eines Mitglieds
     * 
     * @param id User ID
     */
    getOneUser(id: number, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Familienmitglieder eines Mitglieds
     * 
     * @param id User ID
     */
    getUserFamilie(id: number, extraHttpRequestParams?: any): Observable<Array<User>>;

    /**
     * 
     * 
     */
    getUserRights(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Neues Mitglied anlegen
     * 
     * @param user Anlegen eines neuen Users
     */
    newUser(user: User, extraHttpRequestParams?: any): Observable<User>;

    /**
     * Familienmitglieder eines Mitglieds
     * 
     * @param id User ID
     */
    resendWelcomeMail(id: number, extraHttpRequestParams?: any): Observable<Array<User>>;

}

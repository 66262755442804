/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { User } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DevServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Reset Boote Table
     * 
     */
    resetBoote(extraHttpRequestParams?: any): Observable<User>;

    /**
     * Reset Bootslager Table
     * 
     */
    resetBootslager(extraHttpRequestParams?: any): Observable<User>;

    /**
     * Reset Buchhaltung Tables
     * 
     */
    resetBuchhaltung(extraHttpRequestParams?: any): Observable<User>;

    /**
     * Reset News Table
     * 
     */
    resetNews(extraHttpRequestParams?: any): Observable<User>;

    /**
     * Reset Rechnungen Table
     * 
     */
    resetRechnungen(extraHttpRequestParams?: any): Observable<User>;

    /**
     * Reset News Table
     * 
     */
    resetSwBrett(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Reset Termine Table
     * 
     */
    resetTermine(extraHttpRequestParams?: any): Observable<User>;

    /**
     * Reset User Table
     * 
     */
    resetUser(extraHttpRequestParams?: any): Observable<User>;

}

import {Pipe, PipeTransform} from '@angular/core';
import {Boot} from '../api';

@Pipe({
    name: 'bootName',
})
export class BootNamePipe implements PipeTransform {
    /**
     * Outputs a {@link Boot}seigentümer name.
     * @param boot The boot to transform.
     * @param format The format to output, see examples.
     * @throws Error – if boot is not valid.
     */
    public static transform(boot: Boot): string {
        BootNamePipe.validate(boot);

        if (boot.name === '') {
            return 'Boot';
        }
        return boot.name || 'Boot';
    }

    /**
     * Checks whether a user has the required attributes.
     * @throws {@link Error} – if user is not valid.
     */
    private static validate(boot: Boot): void {
        if (!boot) {
            throw new Error('[BootNamePipe] Boot ist ungültig.');
        }
    }

    /**
     * @see {@link BootNamePipe.transform}
     */
    public transform(boot: Boot): string {
        return BootNamePipe.transform(boot);
    }
}

import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/api';

@Component({
    templateUrl: './passwort-vergessen-page.component.html',
    styleUrls: ['./passwort-vergessen-page.component.scss'],
})
export class PasswortVergessenPageComponent implements OnInit {
    pwForm = new UntypedFormGroup({
        benutzername: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required),
    });

    constructor(private authService: AuthService, private router: Router) {}

    vergessen(): void {
        const val = this.pwForm.value;

        if (val.benutzername && val.email) {
            this.authService
                .resetPW(val.benutzername, val.email)
                .toPromise()
                .then(() => {
                    alert('Dein Passwort wird zurück gesetzt. Schau dazu in dein angegebenes Email Postfach.');
                    this.router.navigateByUrl('/login');
                })
                .catch(error => {
                    alert(JSON.stringify(error));
                });
        }
    }

    ngOnInit(): void {}
}

import {Component} from '@angular/core';
import {DeviceScaleService} from 'src/app/services/device-scale.service';

@Component({
    selector: 'kkm-button-menue',
    templateUrl: './button-menue.component.html',
    styleUrls: ['./button-menue.component.scss'],
})
export class ButtonMenueComponent {
    isDesktop;

    constructor(public deviceScaleService: DeviceScaleService) {
        this.isDesktop = !this.deviceScaleService.isMobile();
    }
}

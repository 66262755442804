/**
 * Kanu Klub API
 * App Info Description
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: webadmin@kanu-klub.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Bankkonto } from '../model/models';
import { Beleg } from '../model/models';
import { Buchung } from '../model/models';
import { Buchungskonto } from '../model/models';
import { InlineObject5 } from '../model/models';
import { InlineResponse2001 } from '../model/models';
import { Kassenpruefung } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BuchhaltungServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Delete termin Entry
     * 
     * @param id ID des Eintrags
     */
    deleteBeleg(id: number, extraHttpRequestParams?: any): Observable<Beleg>;

    /**
     * Delete termin Entry
     * 
     * @param id ID des Eintrags
     */
    deleteBuchung(id: number, extraHttpRequestParams?: any): Observable<Buchung>;

    /**
     * Delete Kassenpruefung Entry
     * 
     * @param id ID des Eintrags
     */
    deleteKassenpruefung(id: number, extraHttpRequestParams?: any): Observable<Kassenpruefung>;

    /**
     * Edit termin Entry
     * 
     * @param id ID des Eintrags
     * @param beleg Ändern eines Eintrags
     */
    editBeleg(id: number, beleg: Beleg, extraHttpRequestParams?: any): Observable<Beleg>;

    /**
     * Edit termin Entry
     * 
     * @param id ID des Eintrags
     * @param buchung Ändern eines Eintrags
     */
    editBuchung(id: number, buchung: Buchung, extraHttpRequestParams?: any): Observable<Buchung>;

    /**
     * Edit Kassenpruefung Entry
     * 
     * @param id ID des Eintrags
     * @param kassenpruefung Ändern eines Eintrags
     */
    editKassenpruefung(id: number, kassenpruefung: Kassenpruefung, extraHttpRequestParams?: any): Observable<Kassenpruefung>;

    /**
     * 
     * 
     */
    getBankkonten(extraHttpRequestParams?: any): Observable<Array<Bankkonto>>;

    /**
     * 
     * 
     * @param id User ID
     */
    getBeleg(id: number, extraHttpRequestParams?: any): Observable<Beleg>;

    /**
     * 
     * 
     */
    getBelege(extraHttpRequestParams?: any): Observable<Array<Beleg>>;

    /**
     * 
     * 
     * @param from Datum ab, Format: yyyy-MM-dd
     * @param to Datum bis, Format: yyyy-MM-dd
     */
    getBelegeByRange(from: string, to: string, extraHttpRequestParams?: any): Observable<Array<Beleg>>;

    /**
     * 
     * 
     * @param id User ID
     */
    getBuchung(id: number, extraHttpRequestParams?: any): Observable<Array<Buchung>>;

    /**
     * 
     * 
     */
    getBuchungen(extraHttpRequestParams?: any): Observable<Array<Buchung>>;

    /**
     * 
     * 
     * @param from Datum ab, Format: yyyy-MM-dd
     * @param to Datum bis, Format: yyyy-MM-dd
     */
    getBuchungenByRange(from: string, to: string, extraHttpRequestParams?: any): Observable<InlineResponse2001>;

    /**
     * 
     * 
     */
    getBuchungskonten(extraHttpRequestParams?: any): Observable<Array<Buchungskonto>>;

    /**
     * 
     * 
     * @param id User ID
     */
    getKassenpruefung(id: number, extraHttpRequestParams?: any): Observable<Kassenpruefung>;

    /**
     * 
     * 
     */
    getKassenpruefungen(extraHttpRequestParams?: any): Observable<Array<Kassenpruefung>>;

    /**
     * Neue Buchung anlegen
     * 
     * @param file file to upload
     */
    importBuchungen(file?: Blob, extraHttpRequestParams?: any): Observable<Buchung>;

    /**
     * Edit termin Entry
     * 
     * @param id ID der Buchung
     * @param inlineObject5 
     */
    linkBuchungBeleg(id: number, inlineObject5: InlineObject5, extraHttpRequestParams?: any): Observable<Buchung>;

    /**
     * Neue Beleg anlegen
     * 
     * @param beleg Daten einer neuen Beleg
     */
    newBeleg(beleg: Beleg, extraHttpRequestParams?: any): Observable<Beleg>;

    /**
     * Neue Buchung anlegen
     * 
     * @param buchung Daten einer neuen Buchung
     */
    newBuchung(buchung: Buchung, extraHttpRequestParams?: any): Observable<Buchung>;

    /**
     * Neue Kassenpruefung anlegen
     * 
     * @param kassenpruefung Daten einer neuen Kassenpruefung
     */
    newKassenpruefung(kassenpruefung: Kassenpruefung, extraHttpRequestParams?: any): Observable<Kassenpruefung>;

}

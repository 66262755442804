<span fxFlex></span>
<div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
    fxLayoutGap="20px"
    style="padding: 20px"
    fxFlex.gt-xs="75%"
    fxFlex.xs="100%"
    data-cy="BootshausPage">
    <mat-card fxFlex>
        <mat-card-header>
            <mat-card-title>Unser Bootshaus</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div
                fxLayout.lt-md="column"
                fxLayout.gt-sm="row"
                fxLayoutAlign="space-evenly stretch"
                fxLayoutGap="20px"
                fxFlex>
                <div fxFlex><img src="\assets\img\startseite/haus.jpg" alt="Bootshaus" width="100%" /></div>
                <div fxFlex>
                    Das Bootshaus liegt direkt an der Fähre Mühlheim - Dörnigheim am Main, und ist der zentrale
                    Treffpunkt der PaddlerInnen des KKM. Man kann dort seine Boote lagern, feiern, auf der Terasse
                    sitzen und grillen und natürlich auf dem Main paddeln. Zum Bootshaus gehört direkt am Main der
                    Bootssteg und die Anlage für die Slalomstangen und Polotore. In der Sommersaison sind diese zum
                    Trainieren dort aufgebaut. Finden ist ganz einfach: das Haus mit dem Biber hinter dem Ruderverein.
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<span fxFlex></span>

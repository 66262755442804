<table width="100%">
    <colgroup>
        <col span="1" style="width: 70%" />
        <col span="1" style="width: 30%" />
    </colgroup>
    <tr>
        <th>A: Mitgliedsbeitrag Verein</th>
        <th>Jahresbeitrag</th>
    </tr>
    <tr>
        <td>Erwachsene, aktive Mitglieder</td>
        <td>{{ beitragService.getBeitragbyKuerzel('A1') | currency }}</td>
    </tr>
    <tr>
        <td>Familienmitglied, Jugendliche, Azubis und Studenten</td>
        <td>{{ beitragService.getBeitragbyKuerzel('A2') | currency }}</td>
    </tr>
    <tr>
        <td>Familienbeitrag</td>
        <td>{{ beitragService.getBeitragbyKuerzel('A3') | currency }}</td>
    </tr>
</table>
<p><br /></p>
<table width="100%">
    <colgroup>
        <col span="1" style="width: 45%" />
        <col span="1" style="width: 15%" />
        <col span="1" style="width: 40%" />
    </colgroup>
    <tr>
        <th>B: Nutzungsabhängiger Beitrag</th>
        <th>Betrag</th>
        <th>&nbsp;</th>
    </tr>
    <tr>
        <td>Bootslager bzw. Benutzung Clubboote</td>
        <td>min. {{ beitragService.getBeitragbyKuerzel('B1') | currency }}</td>
        <td>- gemäß Bootshausordnung vom 19.03.2013</td>
    </tr>
    <tr>
        <td>Bootslager für Zweitboot ( etc. )</td>
        <td>min. {{ beitragService.getBeitragbyKuerzel('B2') | currency }}</td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td>nicht abgeleistete Gemeinschaftsarbeiten</td>
        <td>{{ beitragService.getBeitragbyKuerzel('B3') | currency }}</td>
        <td>- einmal pro aktivem Mitglied oder Familie</td>
    </tr>
</table>
<p></p>
<table width="100%">
    <colgroup>
        <col span="1" style="width: 30%" />
        <col span="1" style="width: 10%" />
        <col span="1" style="width: 10%" />
        <col span="1" style="width: 10%" />
        <col span="1" style="width: 25%" />
    </colgroup>
    <tr>
        <th>C: Verbandsbeiträge – jährlich</th>
        <th>DKV</th>
        <th>HKV</th>
        <th>LSB Hessen</th>
        <th>Stand 1.1.2020</th>
    </tr>
    <tr>
        <td>Erwachsene</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C1 DKV') | currency }}</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C1 HKV') | currency }}</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C1 LSB') | currency }}</td>
        <td>
            = Gesamt
            {{
                beitragService.getBeitragbyKuerzel('C1 DKV') +
                    beitragService.getBeitragbyKuerzel('C1 HKV') +
                    beitragService.getBeitragbyKuerzel('C1 LSB') | currency
            }}
        </td>
    </tr>
    <tr>
        <td>Jugendliche 14-18 Jahre</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C3 DKV') | currency }}</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C3 HKV') | currency }}</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C3 LSB') | currency }}</td>
        <td>
            = Gesamt
            {{
                beitragService.getBeitragbyKuerzel('C3 DKV') +
                    beitragService.getBeitragbyKuerzel('C3 HKV') +
                    beitragService.getBeitragbyKuerzel('C3 LSB') | currency
            }}
        </td>
    </tr>
    <tr>
        <td>Jugendliche bis 14 Jahre</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C2 DKV') | currency }}</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C2 HKV') | currency }}</td>
        <td>{{ beitragService.getBeitragbyKuerzel('C2 LSB') | currency }}</td>
        <td>
            = Gesamt
            {{
                beitragService.getBeitragbyKuerzel('C2 DKV') +
                    beitragService.getBeitragbyKuerzel('C2 HKV') +
                    beitragService.getBeitragbyKuerzel('C2 LSB') | currency
            }}
        </td>
    </tr>
    <tr>
        <td><strong>Förderer oder Seniorenbeitrag</strong></td>
        <td colspan="4">
            <strong> </strong>{{ beitragService.getBeitragbyKuerzel('F') | currency }} einschließlich der
            Verbandsbeiträge und Versicherungen (kein Bootslager!)
        </td>
    </tr>
</table>

<p>
    Der Mitgliedsbeitrag setzt sich aus der Summe der Anteile aus den 3 Kategorien <strong>A</strong>,
    <strong>B</strong> und <strong>C</strong> zusammen
</p>

<p>
    Bei neuer Mitgliedschaft (Erwachsene oder Familie) erheben wir einen einmaligen Aufnahmebetrag von
    {{ beitragService.getBeitragbyKuerzel('G') | currency }} (Jugend- und Fördermitgliedschaften ausgenommen). Für die
    zusätzliche Bereitstellung eines Bootsliegeplatzes für ein Privatboot erheben wir einen einmaligen Betrag von
    {{ beitragService.getBeitragbyKuerzel('H') | currency }}.
</p>

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// Angular Common
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

// Material
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

import {FlexLayoutModule} from '@angular/flex-layout';

// KKM Spezifische
import {StartseiteComponent} from './pages/startseite/startseite.component';
import {LayoutComponent} from './pages/layout/layout.component';
import {ImpressumComponent} from './pages/impressum/impressum.component';
import {DisclaimerComponent} from './pages/disclaimer/disclaimer.component';
import {DatenschutzComponent} from './pages/datenschutz/datenschutz.component';
import {LoginComponent} from './pages/login/login.component';
import {VorstandComponent} from './pages/vorstand/vorstand.component';
import {BootshausComponent} from './pages/bootshaus/bootshaus.component';
import {AnmeldungSatzungComponent} from './pages/anmeldung-satzung/anmeldung-satzung.component';
import {KalenderPageComponent} from './pages/kalender-page/kalender-page.component';
import {AnfahrtComponent} from './pages/anfahrt/anfahrt.component';
import {SommertrainingComponent} from './pages/training/sommertraining/sommertraining.component';
import {WintertrainingComponent} from './pages/training/wintertraining/wintertraining.component';
import {NewsPageComponent} from './pages/news-page/news-page.component';
import {PasswortVergessenPageComponent} from './pages/passwort-vergessen-page/passwort-vergessen-page.component';

import {NavigationComponent} from './components/navigation/navigation.component';
import {FooterComponent} from './components/footer/footer.component';
import {DeviceScaleComponent} from './components/device-scale.component';
import {NewsCardLargeComponent} from './components/news-card-large/news-card-large.component';

import {AuthInterceptor} from './Interceptors/AuthInterceptor';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';

import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';

import {BASE_PATH} from './api';
import {LinkyModule} from 'ngx-linky';
import {MatCarouselModule} from '@thouet/material-carousel';
import {LightboxModule} from 'ngx-lightbox';
import {HeaderComponent} from './components/header/header.component';
import {TerminFormComponent} from './components/termin-form/termin-form.component';
import {PipesModule} from './pipes/pipes.module';
import {MaterialModule} from './material.module';
import {BasicModule} from './basic.module';
import {InfocardComponent} from './components/infocard/infocard.component';
import {NgChartsModule} from 'ng2-charts';
registerLocaleData(localeDe, localeDeExtra);

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        NavigationComponent,
        HeaderComponent,
        FooterComponent,

        StartseiteComponent,
        InfocardComponent,

        NewsPageComponent,
        NewsCardLargeComponent,

        KalenderPageComponent,
        TerminFormComponent,

        VorstandComponent,
        BootshausComponent,
        AnmeldungSatzungComponent,
        AnfahrtComponent,

        SommertrainingComponent,
        WintertrainingComponent,

        ImpressumComponent,
        DisclaimerComponent,
        DatenschutzComponent,

        LoginComponent,
        PasswortVergessenPageComponent,

        DeviceScaleComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,

        FlexLayoutModule,
        MaterialModule,
        MatCarouselModule,

        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        LinkyModule,
        LightboxModule,

        BasicModule,
        PipesModule,
        NgChartsModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        // {
        //   provide: DateAdapter,
        //   useClass: MomentDateAdapter,
        //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        // },
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
        {provide: MAT_DATE_LOCALE, useValue: 'de'},
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        {provide: BASE_PATH, useValue: environment.basePath},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {showDelay: 500, hideDelay: 1000}},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
